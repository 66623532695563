import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import { motion } from "framer-motion";
import InputFile from "../../shared/file-uploads/InputFile";
import "./claimsDocuments.css";
import { toast } from "react-toastify";
import { makeGetRequest, makePostRequest } from "../../services/apiHandler";
import Loader from "../../shared/loader/Loader";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

import mtekLogo  from "../../../assets/images/mtek-logo.svg"

const relIcon = require("../../../assets/images/card.png");

function ClaimsDocuments({ setCurrentScreen }: any) {

  const navigate = useNavigate();
  const previusScreen = sessionStorage.getItem("claims-back")!;

  const [claimsDocs, setClaimsDocs] = useState({
    flightConfirmation: "",
    insuranceCertificate: "",
    travelTickets: "",
    caseReport: "",
    evidenceOfOwnership: "",
    proofOfClaimExistence: "",
    otherReceipts: "",
    causeConfirmation: "",
    reasonForConfirmation: "",
    confirmationOfInpatient: "",
    receiptsAmount: "",
    otherDocuments: "",
  });
  const SKEY = sessionStorage.getItem("SKEY") || "";
  const policyNumber = sessionStorage.getItem("policyNumber")!
  const underWritter = sessionStorage.getItem("insurerName")!
  const policyHolder = sessionStorage.getItem("policyHolder")!

  useEffect(() => {
    getSasToken();
    setCurrentScreen("claim-documents");
    sessionStorage.setItem("claim-screens", "claim-documents");
  });

  const [loading, setLoading] = useState<boolean>(false)
  const cInfo = sessionStorage.getItem("claimInfo")!
  const claimInfo = JSON.parse(cInfo)
  const claim_type=sessionStorage.getItem("claim_type")
  const quote_id = sessionStorage.getItem("quote_id")!
  const c = sessionStorage.getItem("claim")!
  const claim = JSON.parse(c)
  const updateDocs = (name: any, url: any) => {
    setClaimsDocs((prevState) => ({
      ...prevState,
      [name]: url,
    }));
  };

  const saveDetails = async() => {
    if(!claimsDocs.flightConfirmation){
      document.getElementById("flightConfirmation")?.focus
      toast.warning("Enter flight confirmation document!")
      return
    }
    if(!claimsDocs.insuranceCertificate){
      document.getElementById("insuranceCertificate")?.focus
      toast.warning("Enter certificate insurance document!")
      return
    }
    if(!claimsDocs.travelTickets){
      document.getElementById("travelTickets")?.focus
      toast.warning("Enter travel ticket!")
      return
    }
    if(!claimsDocs.caseReport){
      document.getElementById("caseReport")?.focus
      toast.warning("Enter Police, Airline or Tour Operator report!")
      return
    }
    if(!claimsDocs.evidenceOfOwnership){
      document.getElementById("evidenceOfOwnership")?.focus
      toast.warning("Enter Evidence of ownership such as original receipts, valuations, credit card receipts!")
      return
    }
    let body:any = {
      claim_name: claim_type,
      insurerName: underWritter,
      policyNumber: policyNumber,
      policyHolder: policyHolder,
      claim: {...claim},
      documents: {...claimsDocs},
      claimAdditionalInfo: {...claimInfo},
    }
    if(claim_type == "Baggage Claim"){
      let bItems = sessionStorage.getItem("baggageItems")!
      let baggageItems = JSON.parse(bItems)
      body.claim_items = [...baggageItems]
    }
     const res: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/claim`,
      {
        _id: quote_id,
        claimBody: {...body}
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (res.Status === 200) {
      setLoading(false);
      toast.success("A claim request has been sent please check your email!");
      setCurrentScreen("claim-confirmation")
      sessionStorage.setItem("claim-screens", "claim-confirmation")
    } else {
      setLoading(false);
      if (res.Status === 541 || res.Status === 401 || res.Status === 543) {
        setLoading(false);
        toast.warning("Session expired please login again!");
        sessionStorage.setItem("isStep", "false");
        navigate("/login");
      } else {
        toast.error("Something went wrong. Please try again!");
      }
    }
  };
  const getSasToken = async () => {
    const result: IApiResponse = await makeGetRequest(
      `${process.env.REACT_APP_CORE_API_URL}/getSASkey`,
      {
        SKEY: SKEY,
      }
    );

    if (result.Status === 200) {
      sessionStorage.setItem("sasToken", result.Payload);
      sessionStorage.setItem("tokenValid", "true");
    }

    if (result.Status === 543) {
      toast.error("Session expired please login to continue!");
      sessionStorage.setItem("tokenValid", "false");
      navigate("/login");
    }

    if (result.Status === 401) {
      toast.error("Invalid token please login to continue!");
      sessionStorage.setItem("tokenValid", "false");
      navigate("/login");
    }
  };
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>

        <div className="cancellation-input">
          <div className="reg-title text-left mb-2">
            <span style={{ color: "#000000" }}>Please submit your</span>{" "}
           <span className="span-title">Documents...</span> 
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Original holiday/flight confirmation and/or receipt or deposit
                  receipt
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"flight-confirmation-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="flightConfirmation"
                  />
                </div>
              </motion.div>
              {/* </div> */}
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Certificate of insurance
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"insurance-certificate-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="insuranceCertificate"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Travel tickets
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"travel-ticket-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="travelTickets"
                  />
                </div>
              </motion.div>
              {/* </div> */}
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Police, Airline or Tour Operator report
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims-document"}
                    aFileName={"case-report-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="caseReport"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Evidence of ownership such as original receipts, valuations,
                  credit card receipts
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"evidence-of-ownership-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="evidenceOfOwnership"
                  />
                </div>
              </motion.div>
              {/* </div> */}
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Proof of cancellation, medical certificateredundancy notice,
                  court summons, etc
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"proof-of-claim-existence-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="proofOfClaimExistence"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Receipts for additional travel and/or accommodationsexpenses
                  (if applicable)
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"other-receipts-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="otherReceipts"
                  />
                </div>
              </motion.div>
              {/* </div> */}
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Confirmation of cause of claim from carrier, breakdown
                  organization or garage, etc
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"cause-confirmation-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="causeConfirmation"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Confirmation from the carrier stating reason for delay
                  including actual travel time
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"reason-for-confirmation"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="reasonForConfirmation"
                  />
                </div>
              </motion.div>
              {/* </div> */}
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Confirmation of In-patient treatment forhospital benefit claim
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"confirmation-of-inpatient-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="confirmationOfInpatient"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Hospital, Doctor, Chemist, Dentist receipts for amounts
                  claimed (Non-UK only)
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"receipts-amounts-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="receiptsAmount"
                  />
                </div>
              </motion.div>
              {/* </div> */}
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label text-left-label-documents mb-2"
                  htmlFor="postal"
                >
                  Any other relevant documentation
                </label>
                <br />
                <div className="id-uploads-kraPhot">
                  <InputFile
                    fileLocation={"travel-claims"}
                    aFileName={"other-document"}
                    allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                    refIcon={relIcon}
                    updateDocs={updateDocs}
                    name="otherDocuments"
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", previusScreen);
                setCurrentScreen(previusScreen);
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div onClick={() => saveDetails()}>
            {loading && <Loader />}
            {!loading && <button className="next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default ClaimsDocuments;
