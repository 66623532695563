import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import { makePostRequest } from "../services/apiHandler";
import Loader from "../shared/loader/Loader";
import mtekLogo  from "../../assets/images/mtek-logo.svg"


function RenewalPayment() {
  const [transactions, onSetTransactions] = useState([]);
  const [isLoadingTransactions, onSetIsLoadingTransactions] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const SKEY = sessionStorage.getItem("SKEY") || "";
  const quote_id = sessionStorage.getItem("quote_id");
  const endDate = sessionStorage.getItem("endDate")!;
  const nextPaymentDate = sessionStorage.getItem("nextPaymentDate")!;
  const paymentOption = sessionStorage.getItem("paymentOption");
  const quote_amount = Number(sessionStorage.getItem("quote_amount"));

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

  const getTransactions = async () => {
    onSetIsLoadingTransactions(true);
    const res: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_MPESA_URL}/listpaymentlogs`,
      {
        paidAmount: { $gt: 0 },
        received_underscore_id: quote_id,
      },
      {
        SKEY: SKEY,
        moduleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );
    if (res.Status == 200) {
      onSetTransactions(res.Payload);
      onSetIsLoadingTransactions(false);
    } else if (res.Status === 541 || res.Status == 543) {
      toast.warning("Session expired please login again!");
      navigate("/login");
    } else {
      toast.error("Something went wrong please try again!");
    }
  };

  const sanitizeTransactionType = (transactionType: string): string => {
    let type = transactionType.toLocaleLowerCase();
    let name = type
      .split(" ")
      .map((word: string) => word[0].toUpperCase() + word.slice(1));
    return name[0];
  };

  const getDate = (timeStamp: number): string => {
    if (isNaN(timeStamp)) {
      return "N/A";
    } else {
      let dateFormat = new Date(timeStamp);
      return dateFormat.toDateString();
    }
  };
  const payNow = () => {
    navigate('/payments/make/mpesa');
  };
  const sanitaizeNextPaymentDate = (): string => {
    let date = "";
    if (paymentOption == "renewal") {
      date = nextPaymentDate
        .split(" ")[0]
        .replaceAll("-", "/")
        .split("/")
        .reverse()
        .join("/");
      return date;
    } else {
      date = endDate;
      return date;
    }
  };
  useEffect(() => {
    if(!SKEY){
      sessionStorage.setItem("fromMyPol", "true");
      navigate("/login")
      return
    }else {
      getTransactions();
    }
  }, [transactions.length]);
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center">
        <span className="span-title">Travel Insurance</span>  
          </div>
        <br />
        <div className="text-center reg-title">
         <span className="span-title">Financed Policies</span> 
        </div>
        <br />

        <div className="my-pol-card mb-4">
          <div className="my-policies-container d-flex justify-content-between align-items-center">
            <div className="payment-information">
              <div className="my-pol-sub-title">Make Your payment</div>
              <div className="my-pol-sub-title sub-title">
                Your payment is due on{" "}
                {sanitaizeNextPaymentDate()}. Please pay early
                enough to avoid cancellation of this policy.
              </div>
            </div>
            <div className="amount">
              {" "}
              <CurrencyFormat
                value={quote_amount}
                displayType={"text"}
                prefix={"Ksh. "}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>

            <div className="my-pol-buy-container text-center" onClick={() => payNow()}>
              Pay Now
            </div>
          </div>
        </div>
        <div className="transactions-header">
          <h2>Transactions</h2>
          <hr />
          {isLoadingTransactions && <Loader />}
          {transactions.length < 1 && !isLoadingTransactions && (
            <p>No previous transactions for this policy!</p>
          )}
          {!isLoadingTransactions &&
            transactions.map((transaction: any, index: number) => {
              return (
                <div className="transactions-listing" key={index}>
                  <div>
                    {" "}
                    <div className="transactions-listing-title">
                      Paid via{" "}
                      {sanitizeTransactionType(transaction?.transactionType)}
                    </div>
                  </div>
                  <div>{getDate(transaction._timestamp)}</div>
                  <div className="transactions-listing-amount">
                    <CurrencyFormat
                      value={transaction?.paidAmount}
                      displayType={"text"}
                      prefix={"Ksh. "}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </motion.div>
    </div>
  );
}

export default RenewalPayment;
