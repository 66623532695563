import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "../../../firebase";
import Steps from "../../components/steps/Steps";
import { isFirefox } from "react-device-detect";
import { makePostRequest } from "../../services/apiHandler";
import { IApiResponse } from "../../models/api-response.interface";
import { useNavigate } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import { useWizard } from "react-use-wizard";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";
import Loader from "../../shared/loader/Loader";

const google = require("../../../assets/images/google.png");
const emailIcon = require("../../../assets/images/email.png");
const passwordIcon = require("../../../assets/images/lock.png");
const or = require("../../../assets/images/or.png");

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function Login({ setCurrentScreen }: any) {
  const logginRememberMe =
    JSON.parse(localStorage.getItem("rememberMe")!) === true;
  const [rememberMe, onSetRememberMe] = useState(logginRememberMe);
  const openAuth = JSON.parse(sessionStorage.getItem("openLogin")!);

  const location = window.location.search;

  const generatedQuoteID = new URLSearchParams(location).get(
    "generatedQuoteId"
  );

  const { nextStep, goToStep } = useWizard();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [emailValid, setEmailValid] = useState(true);
  const [passValid, setPassValid] = useState(true);
  const [userLoading, setUserLoading] = useState(false);

  const step = Number(sessionStorage.getItem("currentStep"));
  const [currentStep, setCurrentStep] = useState(step);

  useEffect(() => {
    setCurrentStep(step);
  });
  const handleClick = () => {
    if (!validateEmail(userInfo.email)) {
      setEmailValid(false);
      return;
    }

    if (userInfo.password.length < 8) {
      setPassValid(false);
      return;
    }

    setEmailValid(true);

    signInWithEmailAndPassword(userInfo.email, userInfo.password).then(
      async (res: any) => {
        setUserLoading(true);
        try {
          if (res.hasOwnProperty("additionalUserInfo")) {
            let user = res.user._delegate;
            const result: IApiResponse = await makePostRequest(
              `${process.env.REACT_APP_CORE_API_URL}/saveNewUser`,
              {
                userName: user.displayName,
                email: user.email,
                firstName: user.userName,
                feduid: user.uid,
                lastName: "",
                phoneNumber: user?.phoneNumber || "",
              },
              {
                SKEY: user.accessToken,
              }
            );

            if (
              result.Status === 200 ||
              result.Status === 201 ||
              result.Status == 501
            ) {
              sessionStorage.setItem("uid", user.uid);
              sessionStorage.setItem("SKEY", user.accessToken);
              sessionStorage.setItem("email", user.email);
              sessionStorage.setItem("userName", user.displayName);

              if (rememberMe) {
                localStorage.setItem("rememberMe", "true");
                localStorage.setItem("uid", user.uid);
                localStorage.setItem("SKEY", user.accessToken);
                localStorage.setItem("email", user.email);
                localStorage.setItem("userName", user.displayName);
                localStorage.setItem("loggedIn", "true");
              } else {
                localStorage.clear();
              }

              if (currentStep > 3) {
                goToStep(currentStep);
              } else {
                if (dispatch(stepActions.onAddStep())) {
                  // nextStep();
                  if (openAuth) {
                    sessionStorage.setItem("openLogin", JSON.stringify(false));
                    if (generatedQuoteID) {
                      sessionStorage.setItem(
                        "openEmail",
                        JSON.stringify(false)
                      );
                    } else {
                      sessionStorage.setItem("openEmail", JSON.stringify(true));
                    }
                    window.dispatchEvent(new Event("storage"));
                  } else {
                    nextStep();
                  }
                }
              }
              setUserLoading(false);
              sessionStorage.setItem("loggedIn", "true");
              toast.success("Logged in successfully!");
            } else if (result.Status === 502 || result.Status === 500) {
              toast.error(
                "Something went wrong. Please try signing up in again!"
              );
              setUserLoading(false);
              return;
            } else {
              toast.error(
                "Something went wrong. Please try signing up in again!"
              );
              setUserLoading(false);
              return;
            }
          } else {
            setUserLoading(false);
          }
        } catch (err) {
          setUserLoading(false);
        }
      }
    );
  };

  const handleGoogleSignIn = () => {
    setUserLoading(true);

    signInWithGoogle().then(async (res: any) => {
      try {
        if (res.hasOwnProperty("additionalUserInfo")) {
          let user = res.user._delegate;
          let userName = user.email.split("@")[0];
          sessionStorage.setItem("uid", user.uid);
          sessionStorage.setItem("SKEY", user.accessToken);
          sessionStorage.setItem("email", user.email);
          sessionStorage.setItem("userName", user.displayName);
          const result: IApiResponse = await makePostRequest(
            `${process.env.REACT_APP_CORE_API_URL}/saveNewUser`,
            {
              userName: user.displayName,
              email: user.email,
              firstName: userName,
              feduid: user.uid,
              lastName: "",
              phoneNumber: user?.phoneNumber || "",
            },
            {
              SKEY: user.accessToken,
            }
          );

          if (
            result.Status === 200 ||
            result.Status === 201 ||
            result.Status === 501
          ) {
            sessionStorage.setItem("uid", user.uid);
            sessionStorage.setItem("SKEY", user.accessToken);
            sessionStorage.setItem("email", user.email);
            sessionStorage.setItem("userName", user.displayName);

            if (rememberMe) {
              localStorage.setItem("rememberMe", "true");
              localStorage.setItem("uid", user.uid);
              localStorage.setItem("SKEY", user.accessToken);
              localStorage.setItem("email", user.email);
              localStorage.setItem("userName", user.displayName);
              localStorage.setItem("loggedIn", "true");
            } else {
              localStorage.clear();
            }

            if (currentStep > 3) {
              goToStep(currentStep);
              window.location.reload();
            } else {
              if (dispatch(stepActions.onAddStep())) {
                // nextStep();
                if (openAuth) {
                  sessionStorage.setItem("openLogin", JSON.stringify(false));
                  if (generatedQuoteID) {
                    sessionStorage.setItem("openEmail", JSON.stringify(false));
                  } else {
                    sessionStorage.setItem("openEmail", JSON.stringify(true));
                  }
                  window.dispatchEvent(new Event("storage"));
                } else {
                  nextStep();
                }
              }
            }
            setUserLoading(false);
            sessionStorage.setItem("loggedIn", "true");
            toast.success("Logged in successfully!");
          } else if (result.Status === 502 || result.Status === 500) {
            toast.error("Something went wrong. Please try logging in again!");
            setUserLoading(false);
            return;
          } else {
            toast.error("Something went wrong. Please try logging in again!");
            setUserLoading(false);
            return;
          }
        } else {
          setUserLoading(false);
        }
      } catch (err) {
        setUserLoading(false);
      }
    });
  };

  const handleForgotPassword = () => {
    setCurrentScreen("reset");
  };

  const validateEmail = (email: any) => {
    //eslint-disable-next-line
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailValid(false);
      } else {
        setEmailValid(true);
      }
    }

    if (name === "password") {
      if (value.length < 8) {
        setPassValid(false);
      } else {
        setPassValid(true);
      }
    }

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputRememberMeChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    onSetRememberMe(value);
  };

  return (
    <motion.div
      className="container"
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      {!openAuth && (
        <div>
          <Steps name="auth" />
          <br />
          <p className="lg-text text-green simple-fast m-10">
            Please sign in or register
          </p>
          <p className="lg-text">to purchase your cover</p>
          <br />
        </div>
      )}

      <br />
      <div className="auth-login">
        <h1 className="auth-login-description">
          Please sign in or register <br />
          <span className="login-info">to purchase your cover...</span>
        </h1>
        {userLoading && <Loader />}
        {!userLoading && (
          <button
            className="btn-sign-with-google"
            onClick={(e) => handleGoogleSignIn()}
          >
            <img src={google} className="me-2" />
            Sign In With Google
          </button>
        )}
        <img src={or} className="image-or" />
        <div className="auth-input-container">
          <img src={emailIcon} alt="email icon" />
          <div className="label">Your email address</div>
          <input
            type="text"
            placeholder="email"
            name="email"
            value={userInfo.email}
            onChange={handleInputChange}
          />
          {!emailValid && (
            <div className="error-msg">
              <MdIcons.MdError />
              <span>Please enter a valid email</span>
            </div>
          )}
        </div>
        <div className="auth-input-container">
          <img src={passwordIcon} alt="password icon" />
          <div className="label">Password</div>
          <input
            type="password"
            placeholder="password"
            name="password"
            value={userInfo.password}
            onChange={handleInputChange}
          />
          {!passValid && (
            <div className="error-msg">
              <MdIcons.MdError />
              <span>Password must be more than 8 characters</span>
            </div>
          )}
        </div>
        <div className="bottom-text-container">
          <div className="rem-container">
            <input
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              checked={rememberMe}
              onChange={handleInputRememberMeChange}
            />
            <span className="rem-text ms-1">Remember me</span>
          </div>
          <div onClick={(e) => handleForgotPassword()} className="forgot-txt">
            Forgot your password?
          </div>
        </div>
        <br />
        {isFirefox && <div className="spacing-wrapper"></div>}
        <br />
        {userLoading && (
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        )}
        <div className="d-flex justify-content-center">
          {userLoading && <Loader />}
          {!userLoading && (
            <button className="pointer-btn" onClick={() => handleClick()}>
              Login
            </button>
          )}
        </div>
        <p className="sign-up-text text-center">
          Don’t have an account?{" "}
          <span onClick={(e) => setCurrentScreen("signup")}>Sign Up</span>
        </p>
        {isFirefox && <div className="spacing-wrapper"></div>}
        <br />
      </div>
    </motion.div>
  );
}

export default Login;
