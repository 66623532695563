import { motion } from "framer-motion";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import * as GrIcons from "react-icons/gr";

function LoanTerms({ setCurrentScreen }: any) {
  const SKEY = sessionStorage.getItem("SKEY") || "";

  const p: any = sessionStorage.getItem("caculatedPlan") || {};

  const plan = JSON.parse(p);

  const policyDuration = sessionStorage.getItem("policyDuration");

  const [processing, setProcessing] = useState(false);
  const [termsInfo, setTermsInfo] = useState({
    terms: "",
  });

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

  const proceed = () => {
    if (!termsInfo.terms) {
      toast.warning("Please Accept terms and conditions!");
      return;
    }

    processPayment();
  };

  const processPayment = () => {
    sessionStorage.setItem("payment-screen", "payment-method");
    sessionStorage.setItem("payment-plan", "true");
    setCurrentScreen("payment-method");
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTermsInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div className="center-div"></div>
        <div className="reg-title text-center mb-2">
          <span className="span-title"> Loan Terms</span>
        </div>
        <br />
        <div className="confirmation-container">
          <div className="confirmation-wapper">
            <div className="mb-2 mt-3 loan-terms-border">
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Loan type:</span>
                <span className="ln-txt bold">Insurance Premium Financing</span>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Lender:</span>
                <span className="ln-txt bold">TrustGro SCA Limited</span>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Period:</span>
                <span className="ln-txt bold">
                  {plan.tenureInMonths} Months
                </span>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Monthly Deduction</span>
                <span className="ln-txt bold">
                  <CurrencyFormat
                    value={plan.minimumPerUnitRepaymentAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Monthly Interest Rate:</span>
                <span className="ln-txt bold">{plan.interest}%</span>
              </div>
            </div>

            <div className="mb-2 mt-3 loan-terms-border">
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Total Insurance Premium</span>
                <span className="ln-txt bold">
                  <CurrencyFormat
                    value={plan.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Deposit</span>
                <span className="ln-txt bold">
                  <CurrencyFormat
                    value={plan.downPaymentPayable}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Loan Amount</span>
                <span className="ln-txt bold">
                  <CurrencyFormat
                    value={plan.loanAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <span className="ln-txt">Total Cost of Credit</span>
                <span className="ln-txt bold">
                  <CurrencyFormat
                    value={plan.totalCostOfCredit}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-1">
                <span className="con-amt-bld">Total Payable</span>
                <span className=" con-amt-bld">
                  <CurrencyFormat
                    value={plan.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
            </div>

            <h4 className="text-center text-primary mt-4">
              <span className="span-title">Loan Schedule</span>
            </h4>

            <div className="mb-2 mt-3 loan-terms-border">
              <div className="d-flex justify-content-between mt-1">
                <span className="bld-tit">Repayment Date</span>
                <span className="bld-tit">Installment Amount</span>
              </div>
              <hr />
              {plan.schedule.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    <div className="d-flex justify-content-between mt-1">
                      <span className="ln-txt">{item.date}</span>
                      <span className="ln-txt bold">
                        <CurrencyFormat
                          value={item.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={0}
                          suffix={" Ksh"}
                        />
                      </span>
                    </div>
                    <hr />
                  </div>
                );
              })}

              <div className="d-flex justify-content-between mt-1">
                <span className="bld-tit">Total Monthly Payments:</span>
                <span className="con-amt-bld">
                  <CurrencyFormat
                    value={plan.totalMonthlyRepayments}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
            </div>

            <div className="mt-5">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                onChange={handleInputChange}
              />
              <span className="terrms-txt">
                I have read, understood and agreed to{" "}
                <a
                  target="_blank"
                  href="https://mtekcdnstoragev2.blob.core.windows.net/admin/TrustGro_IPF_Terms_and_Conditions_29112021.pdf"
                >
                  TrustGro’s Terms and Conditions
                </a>{" "}
                for funding, and hereby authorise mTek to submit an application
                for funding on my behalf to TrustGro, subject to the Terms &
                Conditions
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem("payment-screen", "payment-schedule");
                  setCurrentScreen("payment-schedule");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div className="" onClick={() => proceed()}>
              <button className="next-btn">
                {" "}
                <span>
                  Proceed{" "}
                  <span className="GrIcon ms-3">
                    <GrIcons.GrFormNextLink />
                  </span>
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default LoanTerms;
