import React, { useState } from "react";
import { motion } from "framer-motion";
import CurrencyFormat from "react-currency-format";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const GiCancel = require("../../../../assets/images/GiCancel.png");

function PaymentOpt({ setCurrentScreen }: any) {
  const q: any = sessionStorage.getItem("new_quote")!
    ? sessionStorage.getItem("new_quote")!
    : sessionStorage.getItem("quote")!;

  const [showSucDialog, setShowSucDialog] = useState(false);

  const quote = JSON.parse(q);

  const showDialog = () => {
    let elem: any = document.getElementById("credit-rting-cont") || "";
    elem.style.filter = "blur(5px)";
    elem.style.background = "rgba(0, 0, 0, 0.05)";
    setShowSucDialog(true);
  };

  const hideDialog = (status: any) => {
    let elem: any = document.getElementById("credit-rting-cont") || "";

    elem.style.filter = "none";
    elem.style.background = "none";
    setShowSucDialog(false);
  };

  const handlePP = () => {
    hideDialog("suc");
    sessionStorage.setItem("payment-screen", "credit-form");
    sessionStorage.setItem("paymentOption", "pp");
    setCurrentScreen("credit-form");
  };

  const successDialog = (
    <div className="l-dialog-container l-dialog-container-more-information">
      <div>
        <div
          className="cancel d-flex justify-content-end mb-5"
          style={{ cursor: "pointer" }}
          onClick={hideDialog}
        >
          <img src={GiCancel} height="20" />
        </div>
        <p className="dialog-p-t mt-1">Great Choice!</p>
        <p className="dialog-p mt-4 dialog-p-more-information">
          Please note that we will need to verify your employment details so
          that we can be able to process a convenient payment plan suitable for
          your insurance purchase!
        </p>
        <div className="d-flex flex-column justify-conter-center align-items-center">
          <div onClick={() => handlePP()} className="mt-4 dialog-proc-btn">
            Let’s Proceed
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {showSucDialog && successDialog}
      <div id="credit-rting-cont">
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          exit={{ opacity: 0 }}
        >
          <div className="container">
            <div className="ky-inputs-container">
              <div className="reg-title payment-title text-left">
                We’re almost there.. <br />
                <span className="span-title">
                  How would you like to pay for your premium?
                </span>
              </div>
              <br />
              <div className="payment-options">
                <div
                  className="one-time"
                  onClick={() => {
                    sessionStorage.setItem("payment-screen", "payment-method");
                    sessionStorage.setItem("paymentOption", "direct");
                    setCurrentScreen("payment-method");
                  }}
                >
                  <span>One time pay</span>
                  <span className="option-amount">
                    <CurrencyFormat
                      value={quote?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={" Ksh"}
                    />
                  </span>
                </div>
                {quote.amount >= 10000 && (
                  <div
                    className="payment-plan"
                    onClick={() => {
                      showDialog();
                    }}
                  >
                    <span>Installments</span>
                    <span className="option-amount">
                      Starting from 10,000.00 Kshs
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default PaymentOpt;
