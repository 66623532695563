import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Steps from "../steps/Steps";
import * as GrIcons from "react-icons/gr";
import { useWizard } from "react-use-wizard";
import Select from "react-select";
import { toast } from "react-toastify";
import { stepActions } from "../../store/Steps";
import { useDispatch } from "react-redux";

export interface IRelationship {
  nokRelationship: string;
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function BeneficiaryDetails({ setCurrentScreen }: any) {
  const { previousStep, nextStep, goToStep } = useWizard();
  const dispatch = useDispatch();
  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    nokName: "",
    nokRelationship: "",
    nokPhoneNo: "",
    nokLocation: "",
    nokPostalAddress: "",
    nokPostalCode: "",
  });
  const [selectedOption, setSelectedOption] = useState<IRelationship>();
  const [showRelationship, setOnshowRelationship] = useState<boolean>(false);

  const [notSurePostalDetails, setNotSurePostalDetails] =
    useState<boolean>(false);
  const [notSureLocationDetails, setNotSureLocationDetails] =
    useState<boolean>(false);

  const relationships = [
    { value: "mother", label: "MOTHER" },
    { value: "aunt", label: "AUNT" },
    { value: "brother", label: "BROTHER" },
    { value: "cousin", label: "COUSIN" },
    { value: "grandfather", label: "GRANDFATHER" },
    { value: "grandmother", label: "GRANDMOTHER" },
    { value: "niece", label: "NIECE" },
    { value: "nephew", label: "NEPHEW" },
    { value: "sister", label: "SISTER" },
    { value: "son", label: "SON" },
    { value: "spouse", label: "SPOUSE" },
    { value: "uncle", label: "UNCLE" },
    { value: "daughter", label: "DAUGHTER" },
    { value: "father", label: "FATHER" },
    { value: "other", label: "OTHER" },
  ];

  useEffect(() => {
    sessionStorage.setItem("details-screen", "Beneficiary-screen");
    setCurrentScreen("Beneficiary-screen");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setBeneficiaryInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value == "other") {
      setOnshowRelationship(true);
    } else {
      setOnshowRelationship(false);
    }
    setSelectedOption(selectedOption);
    setBeneficiaryInfo((previousState: any) => ({
      ...previousState,
      nokRelationship: selectedOption.value,
    }));
  };

  const saveDetails = () => {
    if (!beneficiaryInfo.nokName) {
      toast.warning("Enter Beneficiary's Name!");
      document.getElementById("nokName")?.focus();
      return;
    }

    let pattern =
      /^(?=.{3,})([A-Za-z']{3,}\s+){1,2}([A-Za-z']+\s+)*[A-Za-z']+\s*$/;
    if (!pattern.test(beneficiaryInfo.nokName)) {
      toast.warning("Enter At least two names!");
      document.getElementById("nokName")?.focus();
      return;
    }
    if (!selectedOption) {
      toast.warning("Enter Beneficiary's Relationship!");
      return;
    }
    const onValidatePhoneNumber = (phoneNumber: any) => {
      const regex = /^(\+254|0)[1-9]\d{8}$/.test(phoneNumber);
      return regex;
    };
    if (!onValidatePhoneNumber(beneficiaryInfo.nokPhoneNo)) {
      toast.warning("Enter valid phone number!");
      document.getElementById("nokPhoneNo")?.focus();
      return;
    }
    if (!notSurePostalDetails) {
      if (!beneficiaryInfo.nokPostalAddress) {
        toast.warning("Enter Beneficiary's Postal Address!");
        document.getElementById("nokPostalAddress")?.focus();
        return;
      }
      if (!beneficiaryInfo.nokPostalCode) {
        toast.warning("Enter Beneficiary's Postal Code!");
        document.getElementById("nokPostalCode")?.focus();
        return;
      }
    }
    if (!notSureLocationDetails) {
      if (!beneficiaryInfo.nokLocation) {
        toast.warning("Enter Beneficiary's Location!");
        document.getElementById("nokLocation")?.focus();
        return;
      }
    }
    sessionStorage.setItem("beneficiaryInfo", JSON.stringify(beneficiaryInfo));
    sessionStorage.removeItem("details-screen");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };

  const goToThePreviousStep = () => {
    const screen = sessionStorage.getItem("travel_type")!;
    if (screen == "Group") {
      sessionStorage.setItem("details-screen", "Group-screen");
      setCurrentScreen("Group-screen");
    } else {
      if (dispatch(stepActions.onRemoveStep())) {
        sessionStorage.removeItem("details-screen");
        sessionStorage.setItem("docs-current-screen", "insurer-docs");
        previousStep();
      }
    }
  };
  const setNokPostalStatus = () => {
    if (notSurePostalDetails) {
      setNotSurePostalDetails(false);
    } else {
      setNotSurePostalDetails(true);
    }
  };
  const setNokLocationStatus = () => {
    if (notSureLocationDetails) {
      setNotSureLocationDetails(false);
    } else {
      setNotSureLocationDetails(true);
    }
  };

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      <Steps name="beneficiary" />
      <div className="container">
        <div className="ky-inputs-container ky-inputs-container-beneficiary">
          <div className="reg-title text-left">
            Please provide{" "}
            <span className="span-title">beneficiary details...</span>
          </div>
          <br />
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="nokName">
                  Name
                </label>
                <br />
                <input
                  id="nokName"
                  name="nokName"
                  type="text"
                  onChange={handleInputChange}
                  value={beneficiaryInfo.nokName}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="nokRelationship"
                >
                  Relationship{" "}
                </label>
                <br />
                <Select
                  options={relationships}
                  onChange={handleChange}
                  id={"nokRelationship"}
                  className={"nokRelationship"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#F6F8FB",
                      border: state.isFocused ? "none" : "#F6F8FB",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "315px",
                      height: "47px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#00000",
                      fontWeight: "bold",
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "none",
                      marginRight: "1rem",
                    }),
                  }}
                />{" "}
                {showRelationship && (
                  <div className="ky-input-wrapper ky-input-wrapper-other-relationship">
                    <motion.div
                      animate={{ scale: [-0.7, 1] }}
                      transition={{ duration: 1 }}
                      className="ky-input-container mt-1"
                    >
                      <input
                        id="nokRelationship"
                        name="nokRelationship"
                        type="text"
                        className="other-relationship"
                        onChange={handleInputChange}
                        value={beneficiaryInfo.nokRelationship}
                        placeholder="Enter Relationship"
                      ></input>
                    </motion.div>
                  </div>
                )}
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="nokPhoneNo">
                  Phone number{" "}
                </label>
                <br />
                <input
                  id="nokPhoneNo"
                  name="nokPhoneNo"
                  type="text"
                  onChange={handleInputChange}
                  value={beneficiaryInfo.nokPhoneNo}
                  placeholder="phone number"
                ></input>
              </motion.div>
            </div>
          </div>

          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Postal address{" "}
                </label>
                <br />
               {!notSurePostalDetails && <div className="postal-area-container nokDetails">
                  <input
                    id="nokPostalAddress"
                    name="nokPostalAddress"
                    type="text"
                    onChange={handleInputChange}
                    value={beneficiaryInfo.nokPostalAddress}
                    placeholder="P.O.Box"
                  ></input>

                  <input
                    id="nokPostalCode"
                    name="nokPostalCode"
                    type="text"
                    onChange={handleInputChange}
                    value={beneficiaryInfo.nokPostalCode}
                    placeholder="Postal Code"
                  ></input>
                </div>}
                <div
                  className={
                    notSurePostalDetails
                      ? "not-sure mt-2 active-postal-nok"
                      : "not-sure mt-2"
                  }
                  onClick={() => setNokPostalStatus()}
                >
                  <p>I’m not sure</p>
                </div>
              </motion.div>
            </div>

            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="nokLocation">
                  location
                </label>
                <br />
                {!notSureLocationDetails && <div className="location-area-container">
                  <input
                    id="nokLocation"
                    name="nokLocation"
                    type="text"
                    onChange={handleInputChange}
                    value={beneficiaryInfo.nokLocation}
                    placeholder="Type location"
                  ></input>
                </div>}
                <div
                  className={
                    notSureLocationDetails
                      ? "not-sure mt-2 active-location-nok"
                      : "not-sure mt-2"
                  }
                  onClick={() => setNokLocationStatus()}
                >
                  <p>I’m not sure</p>
                </div>
              </motion.div>
            </div>
          </div>

          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper"></div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span onClick={goToThePreviousStep}>
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div onClick={() => saveDetails()}>
              <button className="next-btn">PROCEED</button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </motion.div>
  );
}

export default BeneficiaryDetails;
