import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import Select from "react-select";
import { toast } from "react-toastify";
import * as GrIcons from "react-icons/gr";
import * as IoIcons from "react-icons/io";
import { makeGetRequest, makePostRequest } from "../../services/apiHandler";
import { stepActions } from "../../store/Steps";
import { useDispatch } from "react-redux";

const GiCancel = require("../../../assets/images/GiCancel.png");
const avatar = require("../../../assets/images/user2.png");
interface ITravelInfor {
  startDate: string;
  endDate: string;
  yearOfBirth: string;
  reason: string;
  departureCountry: string;
  destinationCountry: string;
  dateOfBirth: string;
}
const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function GroupDetails({
  setCurrentScreen,
  individualStatus,
  groupStatus,
}: any) {
  const travel_option = sessionStorage.getItem("travel_option")!;

  const dispatch = useDispatch();
  const { previousStep, nextStep } = useWizard();
  const [travelInfo, setTravelInfo] = useState({
    startDate: "",
    endDate: "",
    reason: "",
    departureCountry: travel_option == "country" ? "Kenya" : "",
    destinationCountry: "",
    yearOfBirth: "",
    dateOfBirth: "",
  });

  const [reasons, setTravelReasons] = useState([]);
  const [countries, setCountries] = useState();

  const [amongTravelling, onSetAmongTravelling] = useState<boolean>(false);
  const [showAddGroupMembers, onAddGroupMembers] = useState<boolean>(false);
  const [isTravellingOptions, setIsTravellingOptions] =
    useState<boolean>(false);

  const [groupMembersInfor, setGroupMembersInfo] = useState({
    dateOfBirth: "",
  });
  const [principle, setPrincipleInfomation] = useState<any[]>();
  const [members, setMembersArray] = useState<ITravelInfor[]>([]);
  const [groupMembers, setGroupMembers] = useState<any[]>([]);

  const isTravellingOptionsToMultipleCountries = () => {
    sessionStorage.setItem("isTravellingToMultipleCountries", "true");
    setIsTravellingOptions(true);
  };
  const isNotTravellingOptionsToMultipleCountries = () => {
    sessionStorage.setItem("isTravellingToMultipleCountries", "false");
    setIsTravellingOptions(false);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTravelInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name == "yearOfBirth") {
      let dateOfBirth = new Date(value);

      if (dateOfBirth.getTime() > Date.now()) {
        toast.warn("Invalid Principle age!");
        return;
      }

      let today = new Date().getFullYear();
      let age = today - dateOfBirth.getFullYear();

      let principle = [
        {
          relationship: "PRINCIPLE",
          dayOfBirth: dateOfBirth.getDate(),
          monthOfBirth: dateOfBirth.getMonth() + 1,
          yearOfBirth: dateOfBirth.getFullYear(),
          name: null,
          idNo: null,
          passportNo: null,
          age: age,
          email: "NA",
        },
      ];
      setPrincipleInfomation(principle as any);
    }
  };

  const handleChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      reason: selectedOption.name,
    }));
  };

  const handleCountriesChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      destinationCountry: selectedOption.name,
    }));
  };

  const getReasons = async () => {
    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/getTravelReasons`,
      {}
    );
    if (result.Status == 200) {
      setTravelReasons(result.Payload);
    } else {
      toast.warning("Unable to load travel reasons!");
    }
  };

  const onUpdateToNoTravellingStatus = () => {
    onSetAmongTravelling(false);

    let relationshipIndex = groupMembers.findIndex(
      (obj) => obj.relationship == "PRINCIPLE"
    );
    if (relationshipIndex !== -1) {
      groupMembers.splice(relationshipIndex, 1);
    }
  };
  const onUpdateToYesTravellingStatus = () => {
    onSetAmongTravelling(true);
  };

  const addGroupMembers = () => {
    onAddGroupMembers(true);
  };

  const handleInputChangeForGroupMembers = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setGroupMembersInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    let dateOfBirth = 0;
    let monthOfBirth = 0;
    let yearOfBirth = 0;
    let age = 0;
    if (name == "dateOfBirth" && groupMembersInfor.dateOfBirth) {
      let date = new Date(value);
      dateOfBirth = date.getDate();
      monthOfBirth = date.getMonth() + 1;
      yearOfBirth = date.getFullYear();
      let today = new Date();
      age = today.getFullYear() - yearOfBirth;

      let body: any = {
        name: null,
        idNo: null,
        passportNo: null,
        relationship: "GROUP",
        dayOfBirth: dateOfBirth,
        monthOfBirth: monthOfBirth,
        yearOfBirth: yearOfBirth,
        age: age,
        email: "NA",
      };
      setMembersArray((prevState: any) => [...prevState, body]);
    }
  };

  const implementTravelGroupQuoteMembers = () => {
    let travelMembers: any[] = [];
    if (members.length > 0) {
      travelMembers.push(...members);
    } else {
      if (principle) {
        travelMembers.push(...(principle as any[]), members);
      } else {
        travelMembers.push(members);
      }
    }
    setMembersArray(travelMembers as ITravelInfor[]);
    updateGroupMembers();
  };

  const updateGroupMembers = () => {
    if (principle) {
      setGroupMembers((principle as any[]).concat(members as any[]));
      setGroupMembersInfo({
        dateOfBirth: "",
      });
      return;
    } else {
      setGroupMembers(members);
      setGroupMembersInfo({
        dateOfBirth: "",
      });
    }
   
  };

  const removeGroupMember = (index: number) => {
    const newMembers = groupMembers.filter((_, i) => i !== index);
    setGroupMembers([...newMembers]);
  };

  const saveDetails = () => {
    if (!travelInfo.reason) {
      toast.warning("Please select a reason");
      document.getElementById("reason")?.focus;
      return;
    }

    if (!travelInfo.startDate) {
      toast.warning("Enter Travelling date!");
      document.getElementById("startDate")?.focus;
      return;
    }
    // let today = new Date().getTime();
    // let travellingDate = new Date(travelInfo.startDate).getTime();
    // if (travellingDate < today) {
    //   toast.warning("Enter a valid travelling date!");
    //   return;
    // }

    if (!travelInfo.destinationCountry) {
      toast.warning("Enter Destination Country!");
      document.getElementById("destinationCountry")?.focus;
      return;
    }
    if (!travelInfo.departureCountry) {
      toast.warning("Enter Departure Country!");
      document.getElementById("departureCountry")?.focus;
      return;
    }
    if (!isTravellingOptions) {
      if (!travelInfo.endDate) {
        toast.warning("Enter a valid end date");
        document.getElementById("endDate")?.focus;
        return;
      }
      let today = new Date().getTime();
      let returnDate = new Date(travelInfo.endDate).getTime();
      let startDate = new Date(travelInfo.startDate).getTime();
      if (returnDate < today && returnDate > startDate) {
        toast.warning("Enter a valid return date!");
        return;
      }
    }
    let endDate = new Date(travelInfo.endDate);
    let startDate = new Date(travelInfo.startDate);
    if (endDate < startDate) {
      toast.warning("Please select a valid end date");
      return;
    }
    if (amongTravelling) {
      if (!travelInfo.yearOfBirth) {
        toast.warning("Enter Date of Birth!");
        document.getElementById("yearOfBirth")?.focus;
        return;
      }
    }

    if (groupMembers.length < 1) {
      toast.warning("Please At least one group member!");
      return;
    }

    if (amongTravelling) {
      let relationshipIndex = groupMembers.findIndex(
        (obj) => obj.relationship == "PRINCIPLE"
      );
      if (relationshipIndex == -1) {
        toast.warning("Please add principle!");
        return;
      }
    }

    let date = travelInfo.startDate.replaceAll("-", "/");
    let travelDate = date.split("/").reverse().join("/");
    sessionStorage.setItem("startDate", travelDate);
    sessionStorage.setItem("travelInfo", JSON.stringify(travelInfo));
    sessionStorage.setItem("group_members", JSON.stringify(groupMembers));
    sessionStorage.setItem("travel_type", "Group");

    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };

  const getCountries = async () => {
    const result: IApiResponse = await makeGetRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/countries`
    );
    if (result.Status == 200) {
      setCountries(result.Payload);
    } else {
      toast.warning(result.Payload);
    }
  };

  useEffect(() => {
    getReasons();
    getCountries();
  }, []);
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <div className="ky-inputs-container-1">
        <div className="ky-inputs-inner-container w-100">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="coverType">
                What type of cover do you want?
              </label>
              <br />
              <div className="ky-input-wrapper-type">
                <div
                  className="individual"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentScreen("individual");
                  }}
                >
                  <input
                    id="coverType"
                    name="coverType"
                    type="radio"
                    checked={individualStatus}
                    onChange={handleInputChange}
                    value={travelInfo.startDate}
                  />
                  <span className="ky-individual">Individual</span>
                </div>
                <div
                  className="group"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentScreen("group");
                  }}
                >
                  <input
                    id="coverType"
                    name="coverType"
                    type="radio"
                    checked={groupStatus}
                    onChange={handleInputChange}
                    value={travelInfo.startDate}
                  />
                  <span className="ky-group">Group</span>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="phoneNumber">
                Are you travelling to one or multiple{" "}
                {travel_option == "country" ? "countries" : "towns"}?{" "}
              </label>
              <br />
              <div className="countries d-flex">
                <button
                  className={
                    !isTravellingOptions
                      ? "countries-selection one-country isOption-active"
                      : "countries-selection one-country"
                  }
                  onClick={() => isNotTravellingOptionsToMultipleCountries()}
                >
                  One
                </button>
                <button
                  className={
                    isTravellingOptions
                      ? "countries-selection multiple-country isOption-active"
                      : "countries-selection multiple-country"
                  }
                  onClick={() => isTravellingOptionsToMultipleCountries()}
                >
                  Multiple
                </button>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="ky-inputs-inner-container w-100">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="reason">
                Please tell us your reason for travelling{" "}
              </label>
              <br />
              <Select
                options={reasons}
                onChange={handleChange}
                id={"reason"}
                className={"reason"}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#F6F8FB",
                    border: state.isFocused ? "none" : "#F6F8FB",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                  input: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "315px",
                    height: "47px",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#00000",
                    fontWeight: "bold",
                  }),
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                  }),
                }}
              />{" "}
            </motion.div>
          </div>
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="startDate">
                When are you travelling?
              </label>
              <br />
              <input
                id="startDate"
                name="startDate"
                type="date"
                onChange={handleInputChange}
                value={travelInfo.startDate}
              ></input>
            </motion.div>
          </div>
        </div>
        <div className="ky-inputs-inner-container w-100">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label
                className="text-left-label mb-2"
                htmlFor="departureCountry"
              >
                What {travel_option} are you travelling from (departure)?
              </label>
              <br />
              <input
                id="departureCountry"
                name="departureCountry"
                type="text"
                className={travel_option == "country" ? "country-travel" : ""}
                onChange={handleInputChange}
                value={travelInfo.departureCountry}
                placeholder="Type Your Answer"
              ></input>
            </motion.div>
          </div>

          {travel_option == "town" && (
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="destinationCountry"
                >
                  What is the final {travel_option} you are travelling to
                  (arrival)?
                </label>
                <br />
                <input
                  id="destinationCountry"
                  name="destinationCountry"
                  type="text"
                  onChange={handleInputChange}
                  value={travelInfo.destinationCountry}
                  placeholder="Type location"
                ></input>
              </motion.div>
            </div>
          )}
          {travel_option == "country" && (
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="reason">
                  What is the final country you are travelling to (arrival)?{" "}
                </label>
                <br />
                <Select
                  options={countries}
                  onChange={handleCountriesChange}
                  id={"destinationCountry"}
                  className={"destinationCountry"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#F6F8FB",
                      border: state.isFocused ? "none" : "#F6F8FB",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "315px",
                      height: "47px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#00000",
                      fontWeight: "bold",
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "none",
                    }),
                  }}
                />{" "}
              </motion.div>
            </div>
          )}
        </div>

        <div className="ky-inputs-inner-container w-100">
          <div
            className={
              isTravellingOptions
                ? "ky-input-wrapper disabled-section"
                : "ky-input-wrapper"
            }
          >
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="endDate">
                When are you coming back?
              </label>
              <br />
              <input
                id="endDate"
                name="endDate"
                type="date"
                onChange={handleInputChange}
                value={travelInfo.endDate}
              ></input>
            </motion.div>
          </div>

          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="phoneNumber">
                Are you among those travelling?{" "}
              </label>
              <br />
              <div className="countries d-flex">
                <button
                  className={
                    amongTravelling
                      ? "countries-selection one-country isOption-active"
                      : "countries-selection one-country"
                  }
                  onClick={onUpdateToYesTravellingStatus}
                >
                  Yes
                </button>
                <button
                  className={
                    !amongTravelling
                      ? "countries-selection one-country isOption-active"
                      : "countries-selection one-country"
                  }
                  onClick={onUpdateToNoTravellingStatus}
                >
                  No
                </button>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="ky-inputs-inner-container w-100">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2">
                Please add group members{" "}
              </label>
              <br />
              <div className="group-countries">
                <div className="add-member" onClick={() => addGroupMembers()}>
                  <IoIcons.IoIosAdd />
                  <span className="add-member-text">Add New Member</span>
                </div>
                <div
                  className={
                    showAddGroupMembers
                      ? "ky-input-wrapper"
                      : "ky-input-wrapper disabled-section"
                  }
                >
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="dateOfBirth"
                    >
                      Birth date?{" "}
                    </label>
                    <br />
                    <input
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      onChange={handleInputChangeForGroupMembers}
                      value={groupMembersInfor.dateOfBirth}
                    ></input>
                  </motion.div>
                </div>
                <div
                  className={
                    showAddGroupMembers
                      ? "ky-input-wrapper"
                      : "ky-input-wrapper disabled-section"
                  }
                >
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="startDate">
                      Gender?
                    </label>
                    <br />
                    <div className="ky-input-wrapper-type">
                      <div className="individual gender">
                        <input id="gender" name="gender" type="radio" />
                        <span className="ky-individual">Male</span>
                      </div>
                      <div className="group">
                        <input id="gender" name="gender" type="radio" />
                        <span className="ky-group">Female</span>
                      </div>
                    </div>
                  </motion.div>
                </div>
                <h4
                  className={
                    showAddGroupMembers ? "add" : "add disabled-section"
                  }
                  onClick={() => implementTravelGroupQuoteMembers()}
                >
                  ADD
                </h4>
                <hr />
                <div className="members-container">
                  {groupMembers.length > 0 &&
                    groupMembers.map((item, index) => {
                      return (
                        <div className="group-members" key={index}>
                          <div className="group-member-icons">
                            <div className="avatar-image">
                              <img
                                src={avatar}
                                alt="avatar"
                                style={{ height: "20px", marginLeft: "1rem" }}
                              />
                            </div>
                            <div
                              className="cancel-icon"
                              onClick={() => removeGroupMember(index)}
                            >
                              <img
                                src={GiCancel}
                                alt="cancel"
                                style={{ height: "20px" }}
                              />
                            </div>
                          </div>
                          <div className="group-members-content ms-3 mb-2">
                            <div className="group-members-content-title">
                              {item.relationship}
                            </div>
                            <div className="group-members-content-age">
                              {item.age} Years
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </motion.div>
          </div>
          {amongTravelling && (
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="yearOfBirth">
                  When is your birth date?{" "}
                </label>
                <br />
                <input
                  id="yearOfBirth"
                  name="yearOfBirth"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.yearOfBirth}
                ></input>
              </motion.div>
            </div>
          )}
        </div>

        <div className="mt-2 pointer-button">
          <div className="previous">
            <span onClick={() => previousStep()}>
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <button className="next-btn" onClick={() => saveDetails()}>
            {" "}
            <span>
              Next{" "}
              <span className="GrIcon ms-3">
                <GrIcons.GrFormNextLink />
              </span>
            </span>{" "}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default GroupDetails;
