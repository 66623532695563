import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Steps from "../steps/Steps";
import IndividualDetails from "./IndividualDetails";
import GroupDetails from "./GroupDetails";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function TravelDetails() {
  const [currentScreen, setCurrentScreen] = useState("individual");
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="travel-details" />
      <div className="travel-container">
        <p className="travel-details-title">
          Proceed to provide your{" "}
          <span className="span-title">travel details</span>
        </p>
        {/* Individual form */}
        {currentScreen === "individual" && (
          <IndividualDetails
            setCurrentScreen={setCurrentScreen}
            individualStatus={true}
            groupStatus={false}
          />
        )}
        {/* Group form */}
        {currentScreen === "group" && (
          <GroupDetails
            setCurrentScreen={setCurrentScreen}
            individualStatus={false}
            groupStatus={true}
          />
        )}
      </div>
    </motion.div>
  );
}

export default TravelDetails;
