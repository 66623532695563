import React, { useEffect, useState } from 'react'
import Login from './login/Login';
import Reset from './reset/Reset';
import Signup from './sign-up/Signup';

function Auth() {
    const [currentScreen, setCurrentScreen] = useState("login");
  return (
    <>
        {currentScreen === 'login' && <Login setCurrentScreen={setCurrentScreen}/>}
        {currentScreen === 'signup' && <Signup setCurrentScreen={setCurrentScreen}/>}
        {currentScreen === 'reset' && <Reset setCurrentScreen={setCurrentScreen}/>}
    </>
);
}

export default Auth