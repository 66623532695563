// import React, { useState } from 'react';
// import './auth.css';
// import Login from './Login';
// import Signup from './SignUp';
// import Reset from './Reset';


// const Auth = () => {

//     const [currentScreen, setCurrentScreen] = useState("login");

//     return (
//         <div className="w-100 position-fixed h-100 d-flex flex-column align-items-center justify-content-center">
//             {currentScreen === 'login' && <Login setCurrentScreen={setCurrentScreen} />}
//             {currentScreen === 'signup' && <Signup setCurrentScreen={setCurrentScreen} />}
//             {currentScreen === 'reset' && <Reset setCurrentScreen={setCurrentScreen} />}
//         </div>
//     );
// }

// export default Auth;


import React, { useEffect, useState } from 'react'
import Login from './Login';
import Reset from './Reset';
import Signup from './SignUp';


function Auth() {
    const [currentScreen, setCurrentScreen] = useState("login");
  return (
    <>
        {currentScreen === 'login' && <Login setCurrentScreen={setCurrentScreen}/>}
        {currentScreen === 'signup' && <Signup setCurrentScreen={setCurrentScreen}/>}
        {currentScreen === 'reset' && <Reset setCurrentScreen={setCurrentScreen}/>}
    </>
);
}

export default Auth