import { motion } from "framer-motion";
import React from "react";
import * as GrIcons from "react-icons/gr";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const visaImage = require("../../../../assets/images/visa.png");
const mpesaImage = require("../../../../assets/images/mpesa.png");

function PaymentMethod({ setCurrentScreen }: any) {
  const paymentPlan = sessionStorage.getItem("payment-plan")!;
  const plan = JSON.parse(paymentPlan) === true;
  const paymentOpt = sessionStorage.getItem("paymentOption")!

  const goToPreviousScreens = () => {
    if (plan) {
      sessionStorage.setItem("payment-screen", "loan-terms");
      setCurrentScreen("loan-terms");
    } else {
      sessionStorage.setItem("payment-screen", "payment-opt");
      setCurrentScreen("payment-opt");
    }
  };
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div className="ky-inputs-container payment-method-container">
          <div className="reg-title methods-title text-left">
            So far so good... <br />
            <span className="span-title">
              {" "}
              Please select a payment option to purchase your policy{" "}
            </span>
          </div>
          <div className="methods-ctas">
            <div
              className="mpesa"
              onClick={(e) => {
                sessionStorage.setItem("payment-screen", "mpesa-screen");
                setCurrentScreen("mpesa-screen");
              }}
            >
              <img src={mpesaImage} alt="safaricom icon" />
            </div>
            {paymentOpt == "direct" &&<div
              className="visa"
              onClick={(e) => {
                sessionStorage.setItem("payment-screen", "visa-screen");
                setCurrentScreen("visa-screen");
              }}
            >
              <img src={visaImage} alt="Visa Icon" />
            </div>}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="previous">
              <span onClick={(e) => goToPreviousScreens()}>
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default PaymentMethod;
