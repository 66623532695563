import { toast } from "react-toastify";

export const makeGetRequest = async (apiUrl:string, headers?:any): Promise<any | string> => {
	try{
		const apiResponse = await fetch(apiUrl,
			{
				headers: {
					...headers,
				 }
			}	
		)
		const data = await apiResponse.json()
		return data
	}catch(error:any){
		if(error){
			return error.message;
		}
	}
} 

export const makePostRequest = async (apiUrl:string, body:any, headers?:any): Promise<any | string> => {
	try{
		const apiResponse = await fetch(
			apiUrl,
			{	
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
			    headers: {
                   ...headers,
			      'Content-Type': 'application/json'
			    },
				body: JSON.stringify(body),
			}
		)
		const data = await apiResponse.json()
		return data
	}catch(error:any){ 
		if(error){
			return error.message;
		}
	}
} 

export const saveSharedQuote = async (quote:any, questions_id:string) => {
	try {
		const SKEY = sessionStorage.getItem("SKEY")
	const requestBbody = {
		questions_id,
		...quote,
		ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
	};
	const result: IApiResponse = await makePostRequest(
		`${process.env.REACT_APP_TRAVEL_API_URL}/savequote`,
		{
			...requestBbody,
		},
		{
			SKEY: SKEY,
			ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
			apiKey: `${process.env.REACT_APP_MODULE_ID}`,
		}
	);
	if (result.Status === 200) {
		return result.Payload?._id
	} 
	else {
		toast.error(result.Payload)
	}
	} catch (error:any) {
		toast.error(error.toString())
	}
}
