import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyDYZcTmUhossqtk49Ok7IAVv70mbtRrb8g",
  authDomain: "mtek-9fb22.firebaseapp.com",
  databaseURL: "https://mtek-9fb22.firebaseio.com",
  projectId: "mtek-9fb22",
  storageBucket: "mtek-9fb22.appspot.com",
  messagingSenderId: "225875384266",
  appId: "1:225875384266:web:68e8133414247fcffee133",
  measurementId: "G-7SC94TZJJL",
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    if (user) {
      return res;
    }
  } catch (err) {
    toast.error(err.message);
    return err;
  }
};
const signInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await auth.signInWithEmailAndPassword(email, password);
    const user = res.user;
    if (user) {
      return res;
    }
  } catch (err) {
    toast.error(err.message);
    return err;
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    if (user) {
      return res;
    }
  } catch (err) {
    toast.error(err.message);
    return err;
  }
};
const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    toast.success("Password reset link sent!");
  } catch (err) {
    toast.error(err.message);
    return err;
  }
};
const logout = () => {
  auth.signOut();
};

export {
  auth,
  db,
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
};
