import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makePostRequest } from "../../../../services/apiHandler";
import CurrencyFormat from "react-currency-format";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import * as GrIcons from "react-icons/gr";

const adjustIcon = require("../../../../../assets/images/adjust.png");

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};
function PaymentSchedule({ setCurrentScreen }: any) {
  const q: any = sessionStorage.getItem("new_quote") || {};

  const quote = JSON.parse(q);

  const u: any = sessionStorage.getItem("userInfo") || {};

  const user = JSON.parse(u);

  const SKEY = sessionStorage.getItem("SKEY") || "";

  const cr: any = sessionStorage.getItem("credit_resp") || {};

  const creditResponse = JSON.parse(cr);

  const [processing, setProcessing] = useState(false);
  const [months, setMonth] = useState(3);
  const [day, setDay] = useState(1);
  const [totalPayable, setTotalPayable] = useState(0);
  const [monthlyPayable, setMonthlyPayable] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [selPeriod, setSelPeriod] = useState("ppm3");
  const [maxDepo, setMaxDepo] = useState(200000);

  const proceed = () => {
    sessionStorage.setItem("payment-screen", "loan-terms");
    sessionStorage.setItem('planTenure', String(4))
    setCurrentScreen("loan-terms");
  };

  const calculatePlan = async (depo: number, daym: number, ten: number) => {
    setProcessing(true);

    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_IPF_URL}/getPaymentPlanCalculation`,
      {
        amount: quote.amount,
        depositAmount: depo,
        monthlyRepaymentDay: daym,
        paymentTenure: ten,
        quoteUnderscoreId: quote._id,
        trustGroAffordabilityAmount: creditResponse.affordability,
        trustGroAffordabilityId: creditResponse._id,
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (result.Status === 200) {
      let res = result.Payload[0];

      setTotalPayable(res.totalPayable);
      setMonthlyPayable(res.minimumPerUnitRepaymentAmount);
      setMaxDepo(res.maxDownpaymentPayable);
      setDeposit(res.downPaymentPayable)
      sessionStorage.setItem("quote_amount", res.downPaymentPayable);
      sessionStorage.setItem("caculatedPlan", JSON.stringify(res));
    } else {
      toast.error("Something went wrong please try again!");
      setProcessing(false);
    }
  };

  
  
  useEffect(() => {
    calculatePlan(deposit, day, months);
    calculateDeposit()
  }, []);

  const calculateDeposit = () => {
    let depositAmount = Math.round(quote.amount * 0.2)
    setDeposit(depositAmount)
  }

  const updatePeriod = (id: string, per: number) => {
    setMonth(per);
    let prevElem: any = document.getElementById(selPeriod);
    let elem: any = document.getElementById(id);

    prevElem.classList.remove("sel-per-cls");
    elem.classList.add("sel-per-cls");

    setSelPeriod(id);
    calculatePlan(deposit, day, per);
  };

  const handleDay = (e: any) => {
    let dy = Number(e.target.value);
    setDay(dy);
    calculatePlan(deposit, dy, months);
  };

  const handleDepo = (depo: any) => {
    calculatePlan(depo, day, months);
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div className="center-div"></div>
        <div className="reg-title text-center">
          <span className="span-title"> Payment Schedule</span>
        </div>
        <br />
        <div className="confirmation-container">
          <div className="confirmation-wapper">
            <div className="mt-4">
              <div className="cr-line mb-3"></div>
              <div className="d-flex justify-content-between">
                <div className="cr-b-group-sb-title mb-1 mt-1">
                  Total Payable
                </div>
                <div className="con-amt-bld mb-1 mt-1">
                  <CurrencyFormat
                    value={totalPayable}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="cr-b-group-sb-title mb-1 mt-1">
                  Policy Amount
                </div>
                <div className="con-amt-bld mb-1 mt-1">
                  <CurrencyFormat
                    value={quote.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </div>
              </div>
              <div className="cr-line mt-3"></div>
            </div>

            <p className="text-primary text-center mt-5">
              Select your preferred payment period
            </p>
            <div className="pp-period-selector mt-3">
              <div
                onClick={() => updatePeriod("ppm3", 3)}
                id="ppm3"
                className="pm-per col-3 sel-per-cls"
              >
                3 months
              </div>
              <div
                onClick={() => updatePeriod("ppm6", 6)}
                id="ppm6"
                className="pm-per col-3"
              >
                6 months
              </div>
              <div
                onClick={() => updatePeriod("ppm9", 9)}
                id="ppm9"
                className="pm-per col-3"
              >
                9 months
              </div>
              <div
                onClick={() => updatePeriod("ppm12", 12)}
                id="ppm12"
                className="pm-per col-3"
              >
                12 months
              </div>
            </div>
            <div className="conf-sect-group mb-2 mt-5"></div>

            <p className="text-primary text-center mt-4">
              Which day of the month would you like to make loan repayments?
            </p>

            <div className="d-flex justify-content-center">
              <select onChange={(e) => handleDay(e)} className="day-select">
                <option defaultChecked>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
              </select>
            </div>

            <div className="tiny-blue-line mt-4 mb-4"></div>

            <div className="mb-2 mt-5">
              <div className="d-flex justify-content-between">
                <span className="con-amt-bld">Monthly Payable</span>
                <span className="">
                  <CurrencyFormat
                    value={monthlyPayable}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="con-amt-bld">Deposit</span>
                <span className="">
                  <CurrencyFormat
                    value={deposit}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-5">
              <img src={adjustIcon} alt="adjust" />
            </div>

            <div className="mt-3">
              <InputRange
                maxValue={maxDepo}
                formatLabel={(value) => `${value} Kshs.`}
                minValue={0}
                value={deposit}
                onChange={(value: any) => {
                  setDeposit(value);
                }}
                onChangeComplete={(value: any) => {
                  handleDepo(value);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem("payment-screen", "credit-form");
                  setCurrentScreen("credit-form");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div className="" onClick={() => proceed()}>
              <button className="next-btn">
                {" "}
                <span>
                  Proceed{" "}
                  <span className="GrIcon ms-3">
                    <GrIcons.GrFormNextLink />
                  </span>
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default PaymentSchedule;
