import { motion } from "framer-motion";
import React from "react";
import CurrencyFormat from "react-currency-format";
import { useWizard } from "react-use-wizard";
import * as GrIcons from "react-icons/gr";
import { stepActions } from "../../../store/Steps";
import { useDispatch } from "react-redux";

function CovertDetails({ setCurrentScreen }: any) {
  const { previousStep, nextStep, goToStep } = useWizard();
  const dispatch = useDispatch()
  const q: any = sessionStorage.getItem("quote") || {};
  const quote = JSON.parse(q);

  const startDate = sessionStorage.getItem('startDate')
  const travel_type = sessionStorage.getItem("travel_type")
  const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div className="center-div"></div>
        <div className="reg-title text-center">
          <span className="span-title">Before purchasing,</span> <br />
          let’s confirm that all is good...
        </div>
        <br />
        <div className="confirmation-container">
          <div className="confirmation-wapper">
            <div className="conf-sect-group mb-2">
              <div className="conf-b-group-title mb-1">Cover Details</div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Plan</span>
                <span className="conf-keys">Basic</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Underwriter</span>
                <span className="conf-keys">{quote.insurerName}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Cover</span>
                <span className="conf-keys">{travel_type}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Cover Limit</span>
                <span className="conf-keys"><CurrencyFormat
                    value={quote.coverLimit}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" Ksh"}
                  /></span>
              </div>
            </div>

            <div className="mt-4">
              {/* <div className="cr-line"></div> */}
              <div className="d-flex justify-content-between">
                <div className="conf-b-group-title mb-1 mt-1">Base price </div>
                <div className="conf-b-group-title mb-1 mt-1">
                  <CurrencyFormat
                    value={quote.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" Ksh"}
                  />
                </div>
              </div>
              <div className="cr-line"></div>
            </div>

            <div className="conf-sect-group mb-2 mt-4">
              <div className="conf-b-group-title mb-1">Time</div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Start date</span>
                <span className="conf-keys">{startDate}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Policy Duration</span>
                <span className="conf-keys">{quote.policy.coverDays} Days</span>
              </div>
            </div>

            <div className="conf-sect-group gr-tot mb-2 mt-5">
              <div className="d-flex justify-content-between">
                <span className="con-amt-bld">Amount</span>
                <span className="con-amt-bld">
                  <CurrencyFormat
                    value={quote.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" Ksh"}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span onClick={(e) => {
                sessionStorage.setItem("details-screen","Beneficiary-screen")
                dispatch(stepActions.onRemoveStep())
                previousStep()
                }}>
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div
              onClick={() => {
                sessionStorage.setItem("summary-confirmation-screen","personal-information")
                setCurrentScreen("personal-information")
              }}
            >
              <button className="next-btn">
                {" "}
                <span>
                  Next{" "}
                  <span className="GrIcon ms-3">
                    <GrIcons.GrFormNextLink />
                  </span>
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default CovertDetails;
