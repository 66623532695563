import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Steps from '../steps/Steps';
import BeneficiaryDetails from './BeneficiaryDetails';
import GroupDetails from './group-details/GroupDetails';

const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

function DetailScreens() {
    const screen = sessionStorage.getItem("travel_type")!
    const detail_screen = sessionStorage.getItem("details-screen")!
    const [currentScreen, setCurrentScreen] = useState<string>()
    useEffect(()=> {
        if(detail_screen){
            setCurrentScreen(detail_screen)
        }else {
            if(screen == 'Group'){
                setCurrentScreen("Group-screen")
            }
        }
    })
  return (
    <motion.div variants={variants} initial="enter" animate="center">
    {currentScreen === "Group-screen" && <GroupDetails setCurrentScreen={setCurrentScreen} />}
    {currentScreen === "Beneficiary-screen" && <BeneficiaryDetails setCurrentScreen={setCurrentScreen} />}
    </motion.div>
  )
}

export default DetailScreens