import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import * as GrIcons from "react-icons/gr";
import { useDispatch } from "react-redux";
import { useWizard } from "react-use-wizard";
import { stepActions } from "../../../store/Steps";
import Steps from "../../steps/Steps";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const avatar = require("../../../../assets/images/user2.png");

function GroupDetails({ setCurrentScreen }: any) {
  const { previousStep } = useWizard();
  const dispatch = useDispatch();
  const g = sessionStorage.getItem("group_members")!;

  let gMembers: any[] = JSON.parse(g);
  gMembers = gMembers.filter((element) => element.relationship != "PRINCIPLE");

  const [groupMembers, setGroupMembers] = useState<any[]>(gMembers);
  const [updateName, setUpdateName] = useState<boolean>(false);
  const [index, setIndex] = useState<number>();
  const [memberName, setName] = useState({
    name: "",
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setName((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateGroupMemberName = (index: number) => {
    setUpdateName(true);
    setIndex(index);
  };

  const saveMemberDetails = () => {
    setGroupMembers((prevArray) =>
      prevArray.map((obj, i) => {
        if (i === index) {
          let newObj = {
            name: memberName.name,
            dob: `${obj?.dayOfBirth}/${obj?.monthOfBirth}/${obj?.yearOfBirth}`,
            relationship: obj.relationship,
            email: obj.email,
            years: obj.age,
            uid: 0,
          };
          return newObj;
        }
        return obj;
      })
    );
    setName({
      name: ""
    })
  };

  const saveDetails = () => {
    sessionStorage.setItem("group_members", JSON.stringify(groupMembers));
    sessionStorage.setItem("details-screen", "Beneficiary-screen");
    setCurrentScreen("Beneficiary-screen");
  };

  useEffect(() => {
    sessionStorage.setItem("details-screen", "Group-screen");
  });

  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      <Steps name="group-details" />
      <div className="container">
        <div className="ky-inputs-container">
          <div className="reg-title text-left">
            <span>Please provide</span>
            <span className="span-title">
              {" "}
              names for those travelling
            </span>
          </div>
          <br />
          <div className="members-container members-container-details">
            {groupMembers.length > 0 &&
              groupMembers.map((item, index) => {
                return (
                  <div className="group-members group-member" key={index}>
                    <div className="group-member-icons">
                      <div className="avatar-image mt-2">
                        <img
                          src={avatar}
                          alt="avatar"
                          style={{ height: "20px", marginLeft: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="group-members-content ms-3 mb-2 mt-2">
                      <div className="group-members-content-title">
                        {item?.name}
                      </div>
                      <div className="group-members-content-age">
                        {item?.age} {item?.years} Years
                      </div>
                    </div>
                    <div
                      className="group-member-name ms-3 mb-2 mt-1"
                      onClick={() => updateGroupMemberName(index)}
                    >
                      Add Name
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className={updateName ? "" : "group-member-form"}
          >
            <div className="form">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container form mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="name">
                  Name
                </label>
                <br />
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="group-input-name"
                  onChange={handleInputChange}
                  value={memberName.name}
                  placeholder="Type here"
                ></input>
                <span
                  className="group-btn ms-5"
                  onClick={() => saveMemberDetails()}
                >
                  SAVE
                </span>
              </motion.div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={() => {
                  if (dispatch(stepActions.onRemoveStep())) {
                    sessionStorage.removeItem("details-screen");
                    previousStep();
                  }
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <button className="next-btn" onClick={() => saveDetails()}>
              {" "}
              <span>
                Next{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default GroupDetails;
