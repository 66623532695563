import { createSlice } from "@reduxjs/toolkit";
const step = Number(sessionStorage.getItem("currentStep"))

const StepSlice = createSlice({
    name: "step",
    initialState: {currentStep: step ? step : 0},
    reducers: {
        onAddStep(state){
           state.currentStep  += 1 
           if(state.currentStep > 9) state.currentStep = 9
           sessionStorage.setItem("currentStep",String(state.currentStep))
        },
        onRemoveStep(state){
            state.currentStep -= 1 
            if(state.currentStep < 0) state.currentStep = 0
            sessionStorage.setItem("currentStep",String(state.currentStep))
        },
        updateStepTo(state, action){
            const step = action.payload
            state.currentStep += step
            if(state.currentStep > 9) state.currentStep = 9
            sessionStorage.setItem("currentStep",String(state.currentStep))
        },
        onRemoveStepUpto(state, action){
            const step = action.payload
            state.currentStep -= step
            if(state.currentStep < 0) state.currentStep = 0
            sessionStorage.setItem("currentStep",String(state.currentStep))
        },
        onRestartCurrentStepToDefault(state){
            state.currentStep = 0
            sessionStorage.setItem("currentStep",String(state.currentStep))
        }
    }
})

export const stepActions = StepSlice.actions

export default StepSlice