import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import "./makeClaim.css";
import { toast } from "react-toastify";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

import mtekLogo  from "../../../assets/images/mtek-logo.svg"


function MakeClaim({ setCurrentScreen }: any) {
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentScreen("make-claim");
    sessionStorage.setItem("claim-screens", "make-claim");
  });

  const [claimInfo, setClaimInfo] = useState({
    bookingDate:"",
    startDate: "",
    endDate: "",
    agentName: "",
    accomodationDetails: ""
  })

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setClaimInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveDetails = () => {
    if(!claimInfo.bookingDate){
      document.getElementById('bookingDate')?.focus
      toast.warning("Enter the original booking date!")
      return
    }
    if(!claimInfo.agentName){
      document.getElementById('agentName')?.focus
      toast.warning("Please input name of travel agent/tour operator!")
      return
    }
    if(!claimInfo.startDate){
      document.getElementById('startDate')?.focus
      toast.warning("Enter the travel start date!")
      return
    }
    if(!claimInfo.endDate){
      document.getElementById('endDate')?.focus
      toast.warning("Enter the travel end date!")
      return
    }
    if(!claimInfo.accomodationDetails){
      document.getElementById('accomodationDetails')?.focus
      toast.warning("Please input accommodation details!")
      return
    }
    sessionStorage.setItem("claim", JSON.stringify(claimInfo))
    setCurrentScreen("claim-options");
    sessionStorage.setItem("claim-screens", "claim-options");
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>
            Please provide these details to
          </span>{" "}
          <span className="span-title">make a claim...</span>  
        </div>
        <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="bookingDate">
                When was the trip originally booked?{" "}
              </label>
              <br />
              <input
                id="bookingDate"
                name="bookingDate"
                type="date"
                onChange={handleInputChange}
                value={claimInfo.bookingDate}
              ></input>
            </motion.div>
          </div>
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label
                className="text-left-label mb-2"
                htmlFor="agentName"
              >
                Please input name of travel agent/tour operator
              </label>
              <br />
              <input
                id="agentName"
                name="agentName"
                type="text"
                onChange={handleInputChange}
                value={claimInfo.agentName}
                placeholder="Type here"
              ></input>
            </motion.div>
          </div>
        </div>
        <div className="ky-inputs-inner-container w-100">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="postal">
                Please indicate travel dates (start Date - End Date)
              </label>
              <br />
              <div className="postal-area-container">
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  onChange={handleInputChange}
                  value={claimInfo.startDate}
                ></input>

                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={handleInputChange}
                  value={claimInfo.endDate}
                ></input>
              </div>
            </motion.div>
          </div>

          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label
                className="text-left-label mb-2"
                htmlFor="accomodationDetails"
              >
                Please input accommodation details
              </label>
              <br />
              <div className="postal-area-container">
                <input
                  id="accomodationDetails"
                  name="accomodationDetails"
                  type="text"
                  onChange={handleInputChange}
                  value={claimInfo.accomodationDetails}
                  placeholder="Type here"
                ></input>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.removeItem("claim-screens");
                navigate(-1);
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default MakeClaim;
