import React from 'react'
import { Wizard } from 'react-use-wizard';
import Auth from '../auth/Auth';
import DetailScreens from './beneficiary-details/DetailScreens';
import GetStarted from './get-started/GetStarted';
import DetailsOfInsured from './insurer-details/DetailsOfInsured';
import Payments from './payments/Payments';
import PrepareDocuments from './prepare-documents/PrepareDocuments';
import Quotes from './quotes/Quotes/Quotes';
import SummaryInformation from './summary-confirmation/SummaryInformation';
import TravelDetails from './travel-details/TravelDetails';

function Steps() {
    
    const currentStep = Number(sessionStorage.getItem("currentStep"));

    return (
      <Wizard startIndex={currentStep ? currentStep : 0}>
        <GetStarted />
        <TravelDetails />
        <Quotes />
        <Auth />
        <PrepareDocuments />
        <DetailsOfInsured />
        <DetailScreens />
        <SummaryInformation />
        <Payments />
      </Wizard>
    );
}

export default Steps