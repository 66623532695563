import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { makePostRequest } from "../services/apiHandler";
import Loader from "../shared/loader/Loader";
import CurrencyFormat from "react-currency-format";
import mtekLogo from "../../assets/images/mtek-logo.svg"

function Policies() {
  const SKEY = sessionStorage.getItem("SKEY") || "";
  const feduid = sessionStorage.getItem("uid");
  const navigate = useNavigate();

  const [policies, setPolicies] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [allPolicies, setAllpolicies] = useState<any>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

  const getQuotes = async () => {
    setLoading(true);
    const res: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_CORE_API_URL}/listquote`,
      {
        paidamount: {
          $gt: 0,
        },
        product: "travel",
        feduid: feduid,
        fields: [
          "_id",
          "insurer",
          "insurerName",
          "premium",
          "amount",
          "stage",
          "starttimestamp",
          "_timestamp",
          "endtimestamp",
          "salesDateTimestamp",
          "paidamount",
          "policyNumber",
          "tierVal",
          "questions_id",
          "policy",
          "paymentdetails.bank",
          "paymentdetails.plan",
          "paymentdetails.moreDetails",
          "paymentdetails.nextPaymentDate",
        ],
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (res.Status === 200) {
      let policiesData = res.Payload;
      if (policiesData.length < 1) {
        setLoading(false);
      }
      policiesData.forEach((policy: any, index: number) => {
        getQuestions(policy["questions_id"], index);
      });
      setPolicies(policiesData);
    } else {
      setLoading(false);
      if (res.Status === 541 || res.Status === 401 || res.Status === 543) {
        setLoading(false);
        sessionStorage.setItem("fromMyPol", "true");
        toast.warning("Session expired please login again!");
        navigate("/login");
      } else {
        toast.error("Something went wrong. Please try again!");
      }
    }
  };

  const getQuestions = async (questions_id: string, index: number) => {
    const res: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/getquestions`,
      {
        _id: questions_id,
        fields: ["moreQuestions.client.userName"],
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );
    let response = res.Payload;
    if (response?.hasOwnProperty("moreQuestions")) {
      if (response.moreQuestions?.hasOwnProperty("client")) {
        if (policies[index]) {
          policies[index]["client"] = {
            userName: response.moreQuestions?.client.userName,
          };
          setAllpolicies([...policies]);
        }
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const makePayment = async (
    quoteId: string,
    endDate: string,
    amount: string,
    insurerName: string,
    moreDetails: any,
    bank: string,
    plan: number,
    nextPaymentDate: string
  ) => {
    sessionStorage.setItem("endDate", endDate);
    sessionStorage.setItem("quote_insurerName", insurerName);
    sessionStorage.setItem("quote_id", quoteId);

    if (bank == "TRUSTGRO") {
      sessionStorage.setItem("caculatedPlan", JSON.stringify(moreDetails));
      sessionStorage.setItem(
        "quote_amount",
        moreDetails.minimumPerUnitRepaymentAmount
      );
      sessionStorage.setItem("paymentOption", "renewal");
      sessionStorage.setItem("planTenure", String(plan));
      sessionStorage.setItem("nextPaymentDate", nextPaymentDate);
    } else {
      sessionStorage.setItem("quote_amount", amount);
      sessionStorage.removeItem("paymentOption");
    }
    navigate("/payments/renewal");
  };

  const makeClaim = async (
    _id: string,
    insurerName: string,
    policyNumber: string,
    policyHolder: string
  ) => {
    setLoading(true);
    sessionStorage.setItem("quote_id", _id);
    sessionStorage.setItem("policyNumber", policyNumber);
    sessionStorage.setItem("insurerName", insurerName);
    sessionStorage.setItem("policyHolder", policyHolder);
    navigate("/claims");
  };

  const handleBuy = (quote: any) => {
    sessionStorage.setItem("new_quote", JSON.stringify(quote));
    // navigate("/payments/option");
  };

  const sanitizePackageName = (tierVal: string) => {
    let name = "";
    if (tierVal) {
      name = tierVal.replace("_", " ");
      return name;
    }
    return name;
  };

  const sanitizeStartDate = (policy: any): string => {
    let startDate = "";
    if (policy) {
      startDate = `${policy.startDay}/${policy.startMonth}/${policy.startYear}`;
      return startDate;
    }
    return startDate;
  };
  const sanitizeEndDate = (policy: any): string => {
    let endDate = "";
    if (policy) {
      endDate = `${policy.endDay}/${policy.endMonth}/${policy.endYear}`;
      return endDate;
    }
    return endDate;
  };
  useEffect(() => {
    if (!SKEY) {
      sessionStorage.setItem("fromMyPol", "true");
      navigate("/login");
      return;
    } else {
      getQuotes();
    }
  }, [policies.length]);

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center">
          <span className="span-title">Travel Insurance</span>
        </div>
        <div
          className="text-center mt-4 mb-1 d-flex justify-content-center"
          style={{ color: "#30C2DB" }}
        >
          <span
            className="bs-link-n text-primary text-bold"
            onClick={() => navigate("/policies")}
          >
            My policies
          </span>
          <span className="bs-link-n" onClick={() => navigate("/list-claims")}>
            My claims
          </span>
        </div>
        <br />
        <div className="px-2">
          <div className="my-policies-container">
            {loading && <Loader />}
            {!loading && allPolicies.length < 1 && (
              <h1 className="text-center mt-2">You have no policies yet!</h1>
            )}
            {allPolicies.length > 0 &&
              allPolicies
                .sort((a: any, b: any) => {
                  return b?._timestamp - a?._timestamp;
                })
                .map((item: any, index: any) => {
                  return (
                    <div key={index} className="my-pol-card mb-4">
                      <div className="my-pol-det-container">
                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Policy Holder</div>
                          <div className="my-pol-tit">
                            {item?.client?.userName}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Start Date</div>
                          <div className="my-pol-tit">
                            {sanitizeStartDate(item?.policy)}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4 mp-2">
                          <div className="my-pol-sub-title">Policy Number</div>
                          <div className="my-pol-tit">{item?.policyNumber}</div>
                        </div>
                        <div className="col-md-12 col-lg-2 mp-2">
                          <div className="my-pol-sub-title">Cover</div>
                          <div className="my-pol-tit">
                            {sanitizePackageName(item?.tierVal)}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Underwriter</div>
                          <div className="my-pol-tit">{item?.insurerName}</div>
                        </div>
                        <div className="col-md-6 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">Premium</div>
                          <div className="my-pol-tit">
                            <CurrencyFormat
                              value={item?.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />{" "}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-3 mp-2">
                          <div className="my-pol-sub-title">End Date</div>
                          <div className="my-pol-tit">
                            {sanitizeEndDate(item?.policy)}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 my-pol-cta-container">
                        <div className="w-100">
                          <div className="my-pol-line"></div>
                        </div>
                        <div className="d-flex justify-content-end">
                          {/* {item.stage !== "PAY" && (
                            <div
                              onClick={() => {
                                handleBuy(item);
                              }}
                              className="my-pol-buy-container"
                            >
                              Buy Policy
                            </div>
                          )} */}
                          {processing && (
                            <div>
                              <Loader />
                            </div>
                          )}

                          {!processing &&
                            item.stage === "PAY" &&
                            item?.paymentdetails?.bank == "TRUSTGRO" && (
                              <div
                                className="my-pol-buy-container"
                                onClick={() =>
                                  makePayment(
                                    item?._id,
                                    item?.endDate ? item?.endDate : "",
                                    item?.amount,
                                    item?.insurerName,
                                    item?.paymentdetails?.moreDetails,
                                    item?.paymentdetails?.bank,
                                    item?.paymentdetails?.plan,
                                    item?.paymentdetails?.nextPaymentDate
                                  )
                                }
                              >
                                Make a Payment
                              </div>
                            )}

                          {item.stage === "PAY" && (
                            <div
                              onClick={() =>
                                makeClaim(
                                  item?._id,
                                  item?.insurerName,
                                  item?.policyNumber,
                                  item?.client?.userName
                                )
                              }
                              className="my-pol-claim-container"
                            >
                              Make claim
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default Policies;
