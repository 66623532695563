import React, { useEffect, useState } from 'react'
import BaggageAdditionalInformation from './baggage-claim/BaggageAdditionalInformation';
import BaggageClaim from './baggage-claim/BaggageClaim';
import Cancellation from './cancellation-claim/Cancellation';
import ClaimOptions from './claim-options/ClaimOptions';
import ClaimsDocuments from './claims-documents/ClaimsDocuments';
import ClaimConfirmation from './confirmation/ClaimConfirmation';
import CurtailmentClaim from './curtailment-claim/CurtailmentClaim';
import DelayClaim from './delay/DelayClaim';
import DepartureClaim from './departure/DepartureClaim';
import MedicalExpenseClaim from './expenses-claim/MedicalExpenseClaim';
import LiabilityClaim from './liability/LiabilityClaim';
import MakeClaim from './make-claim/MakeClaim';


function Claims() {

  const [currentScreen, setCurrentScreen] = useState<string>()
  const screen = sessionStorage.getItem("claim-screens")
  useEffect(()=> {
    if(screen){
      setCurrentScreen(screen)
    }else {
      setCurrentScreen("make-claim")
    }
  })
  return(
    <>
    { currentScreen === 'make-claim' && <MakeClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-options' && <ClaimOptions setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-curtailment' && <CurtailmentClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-cancellation' && <Cancellation setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-departure' && <DepartureClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-delay' && <DelayClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-liability' && <LiabilityClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'baggage-claim' && <BaggageClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'baggage-claim-additional' && <BaggageAdditionalInformation setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-medical' && <MedicalExpenseClaim setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-documents' && <ClaimsDocuments setCurrentScreen={setCurrentScreen} />}
    { currentScreen === 'claim-confirmation' && <ClaimConfirmation setCurrentScreen={setCurrentScreen} />}
    </>
  )
}

export default Claims