import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Steps from '../steps/Steps'
import Confirmation from './confirmation/Confirmation';
import Mpesa from './payment-methods/mpesa/Mpesa';
import PaymentMethod from './payment-methods/PaymentMethod';
import Visa from './payment-methods/visa/Visa';
import PaymentOpt from './payment-option/PaymentOpt';
import CreditForm from './payment-plan/credit-form/CreditForm';
import LoanTerms from './payment-plan/loan-terms/LoanTerms';
import PaymentSchedule from './payment-plan/payment-schedule/PaymentSchedule';

const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

function Payments() {
  const [currentScreen, setCurrentScreen] = useState<string>()
  const paymentScreen = sessionStorage.getItem("payment-screen")
  useEffect(()=> {
    if(paymentScreen){
      setCurrentScreen(paymentScreen)
    }else {
      setCurrentScreen("payment-opt")
    }
  })
  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
    >
      <Steps name="payment" />
      {currentScreen === "payment-opt" && <PaymentOpt setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "credit-form" && <CreditForm setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "payment-method" && <PaymentMethod  setCurrentScreen={setCurrentScreen}/>}
      {currentScreen === "payment-schedule" && <PaymentSchedule setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "loan-terms" && <LoanTerms setCurrentScreen={setCurrentScreen}  />}
      {currentScreen === "visa-screen" && <Visa setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "mpesa-screen" && <Mpesa setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "payment-confirmation" && <Confirmation setCurrentScreen={setCurrentScreen}  />}
      </motion.div>
  )
}

export default Payments