import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useWizard } from "react-use-wizard";
import Steps from "../steps/Steps";
import * as GrIcons from "react-icons/gr";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};
function PrepareDocuments() {
  const { previousStep, nextStep, goToStep } = useWizard();

  const SKEY = sessionStorage.getItem("SKEY")!;
  const dispatch = useDispatch();
  const getToPreviousScreen = () => {
    if (SKEY) {
      if (dispatch(stepActions.onRemoveStepUpto(2))) {
        goToStep(2);
      }
    } else {
      if (dispatch(stepActions.onRemoveStep())) {
        previousStep();
      }
    }
  };


  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="prepare" />
      <div className="container">
        <div className="title">
          <div className="prepare-title span-title">
            Now, let’s help you buy your insurance!
          </div>
          <div className="prepare-title-2">
            Please ready the following documents for upload...
          </div>
        </div>
        <div className="prepare-details">
          <ul>
            <li>National ID/ Passport</li>
            <li>KRA PIN Certificate</li>
          </ul>
        </div>
        <div className="prepare-details-btn text-center">
          <button
            className="pointer-btn"
            onClick={() => {
              if(dispatch(stepActions.onAddStep())){
                nextStep();
              }
            }}
          >
            Let’s Proceed
          </button>
        </div>
        <div className="previous">
          <span onClick={getToPreviousScreen}>
            <span className="GrIcon me-2">
              <GrIcons.GrFormPreviousLink />
            </span>{" "}
            <span className="previous">Previous</span>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default PrepareDocuments;
