import React, { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { motion } from "framer-motion";
import "../../travel.css";
import Steps from "../steps/Steps";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";
import { useNavigate } from "react-router-dom";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function GetStarted() {
  const navigate = useNavigate();
  const [onGetStarted, onShowGetStartedPage] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { nextStep, goToStep } = useWizard();

  const handleInternationlClick = () => {
    sessionStorage.setItem("travel_option", "country");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };

  const handleLocalClick = () => {
    sessionStorage.setItem("travel_option", "town");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };
  const getStarted = () => {
    onShowGetStartedPage(true);
  };

  const location = window.location.search;
  const generatedQuoteID = new URLSearchParams(location).get(
    "generatedQuoteId"
  );

  const goToGetQuotes = () => {
    if (generatedQuoteID) {
      goToStep(2);
    }
  };

  useEffect(() => {
    goToGetQuotes();
  }, [generatedQuoteID]);

  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <Steps name="get-started" />
      {onGetStarted && (
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          className="get-started-buttons"
        >
          <div className="travel-options">
            <p className="travel-instruction">
              What would you like to be covered for?
            </p>
            <div className="travel-buttons">
              <button
                className="travel-button me-5"
                onClick={handleInternationlClick}
              >
                International Travel
              </button>
              <button className="travel-button" onClick={handleLocalClick}>
                Local Travel
              </button>
            </div>
          </div>
        </motion.div>
      )}
      {!onGetStarted && (
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          className="get-started"
        >
          <span className="simple">It’s simple & fast!</span>
          <span className="simple simple-vehicle-details">
            Provide travel details, get your quote, pay & receive your{" "}
            <span className="simple-sticker">policy instantly!</span>
          </span>
          <button className="button-get-started" onClick={getStarted}>
            Get Started
          </button>
          <a className="view-policies" onClick={() => navigate("/policies")}>
            View Policies
          </a>
        </motion.div>
      )}
    </motion.div>
  );
}

export default GetStarted;
