import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as GrIcons from "react-icons/gr";
import { useWizard } from "react-use-wizard";
import { makeGetRequest } from "../../../services/apiHandler";
import { stepActions } from "../../../store/Steps";
import { useDispatch } from "react-redux";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function InsurerDetails({ setCurrentScreen }: any) {
  const { previousStep, goToStep } = useWizard();
  const [userInfo, setUserInfo] = useState({
    email: "",
    userName: "",
    phoneNumber: "",
    location: "",
    postaladdress: "",
    postalcode: "",
    gender: "",
  });
  const dispatch = useDispatch();
  const SKEY = sessionStorage.getItem("SKEY") || ""

  const saveDetails = () => {
    if (!userInfo.userName) {
      toast.warning("Enter your name!");
      document.getElementById("userName")?.focus();
      return;
    }

    let pattern = /^(?=.{3,})([A-Za-z']{3,}\s+){1,2}([A-Za-z']+\s+)*[A-Za-z']+\s*$/;
    if (!pattern.test(userInfo.userName)) {
      toast.warning("Enter At least two names!");
      document.getElementById("userName")?.focus();
      return;
    }
    const validateEmail = (email: string) => {
      let emailPattern =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        );
      return emailPattern;
    };
    if (!validateEmail(userInfo.email)) {
      toast.warning("Enter Valid Email!");
      document.getElementById("email")?.focus();
      return;
    }
    if (!userInfo.postaladdress) {
      toast.warning("Enter postal address!");
      document.getElementById("postaladdress")?.focus();
      return;
    }

    if (!userInfo.postalcode) {
      toast.warning("Enter postal code!");
      document.getElementById("postalcode")?.focus();
      return;
    }
    if (!userInfo.gender) {
      toast.warning("Please Select gender!");
      document.getElementById("gender")?.focus();
      return;
    }
    const onValidatePhoneNumber = (phoneNumber: any) => {
      const regex = /^(\+254|0)[1-9]\d{8}$/.test(phoneNumber);
      return regex;
    };
    if (!onValidatePhoneNumber(userInfo.phoneNumber)) {
      toast.warning("Enter valid phone number!");
      document.getElementById("phoneNumber")?.focus();
      return;
    }
    if (!userInfo.location) {
      toast.warning("Enter postal location!");
      document.getElementById("location")?.focus();
      return;
    }

    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    sessionStorage.setItem("docs-current-screen", "insurer-docs");
    setCurrentScreen("insurer-docs");
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getSasToken = async()=> {
    const result: IApiResponse = await makeGetRequest(
      `${process.env.REACT_APP_CORE_API_URL}/getSASkey`,
      {
        SKEY: SKEY,
      }
    );

    if (result.Status === 200) {
      sessionStorage.setItem("sasToken", result.Payload);
      sessionStorage.setItem("tokenValid", "true");
    }

    if (result.Status === 543) {
      toast.error("Session expired please login to continue!");
      sessionStorage.setItem("tokenValid", "false");
      goToStep(3);
    }

    if (result.Status === 401) {
      toast.error("Invalid token please login to continue!");
      sessionStorage.setItem("tokenValid", "false");
      goToStep(3);
    }
  }
  useEffect(() => {
    getSasToken();
  }, []);
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
      >
        <div className="ky-inputs-container ky-inputs-container-insurer">
          <div className="reg-title text-left">
            Let’s get you<span className="span-title"> covered...</span>
          </div>
          <br />
          <div className="ky-inputs-inner-container ky-inputs-inner-container-insurer w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="userName">
                  Your full name
                </label>
                <br />
                <input
                  id="userName"
                  name="userName"
                  type="text"
                  onChange={handleInputChange}
                  value={userInfo.userName}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="email">
                  Your Email Address{" "}
                </label>
                <br />
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={handleInputChange}
                  value={userInfo.email}
                  placeholder="Type email"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Your postal address{" "}
                </label>
                <br />
                <div className="postal-area-container">
                  <input
                    id="postaladdress"
                    name="postaladdress"
                    type="text"
                    onChange={handleInputChange}
                    value={userInfo.postaladdress}
                    placeholder="P.O.Box"
                  ></input>

                  <input
                    id="postalcode"
                    name="postalcode"
                    type="text"
                    onChange={handleInputChange}
                    value={userInfo.postalcode}
                    placeholder="Postal Code"
                  ></input>
                </div>
              </motion.div>
            </div>
          </div>

          <div className="ky-inputs-inner-container ky-inputs-inner-container-insurer w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="gender">
                  Gender
                </label>
                <br />
                <div className="ky-input-wrapper-type">
                  <div className="male d-flex justify-content-start me-5">
                    <input
                      id="gender"
                      name="gender"
                      type="radio"
                      onChange={handleInputChange}
                      value="male"
                      placeholder="Type location"
                    ></input>
                    <label className="ms-2">Male</label>
                  </div>
                  <div className="female d-flex justify-content-start align-items-center">
                    <input
                      id="gender"
                      name="gender"
                      type="radio"
                      onChange={handleInputChange}
                      value="female"
                      placeholder="Type location"
                    ></input>
                    <label className="ms-2">Female</label>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="phoneNumber">
                  Phone number{" "}
                </label>
                <br />
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  onChange={handleInputChange}
                  value={userInfo.phoneNumber}
                  placeholder="Type phone number"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="location">
                  Your location
                </label>
                <br />
                <input
                  id="location"
                  name="location"
                  type="text"
                  onChange={handleInputChange}
                  value={userInfo.location}
                  placeholder="Type location"
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  if (dispatch(stepActions.onRemoveStep())) {
                    previousStep();
                  }
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div onClick={() => saveDetails()}>
              <button className="next-btn">PROCEED</button>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default InsurerDetails;
