import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import { toast } from "react-toastify";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

import mtekLogo  from "../../../assets/images/mtek-logo.svg"

function DelayClaim({ setCurrentScreen }: any) {
  const navigate = useNavigate();
  const [delayInfo, setDelayInfo] = useState({
    scheduledStartDate: "",
    scheduledEndDate: "",
    actualStartDate: "",
    actualEndDate: "",
    delayHours: 0,
    flightNumber: "",
    airline: ""
  })

  useEffect(() => {
    setCurrentScreen("claim-delay");
    sessionStorage.setItem("claim-screens", "claim-delay");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setDelayInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveDetails = () => {

    if(!delayInfo.scheduledStartDate){
      document.getElementById("scheduledStartDate")?.focus
      toast.warning("Enter scheduled start date!")
      return 
    }
    if(!delayInfo.scheduledEndDate){
      document.getElementById("scheduledEndDate")?.focus
      toast.warning("Enter scheduled end date!")
      return 
    }
    if(!delayInfo.actualStartDate){
      document.getElementById("actualStartDate")?.focus
      toast.warning("Enter actual start date!")
      return 
    }

    if(!delayInfo.actualEndDate){
      document.getElementById("actualEndDate")?.focus
      toast.warning("Enter actual end date!")
      return 
    }
    if(!delayInfo.delayHours){
      document.getElementById("delayHours")?.focus
      toast.warning("Enter delay hours!")
      return 
    }
    if(!delayInfo.flightNumber){
      document.getElementById("flightNumber")?.focus
      toast.warning("Enter flight number!")
      return 
    }
    if(!delayInfo.airline){
      document.getElementById("airline")?.focus
      toast.warning("Enter airline company!")
      return 
    }
    setCurrentScreen("claim-documents");
    sessionStorage.setItem("claims-back", "claim-delay");
    sessionStorage.setItem("claim-screens", "claim-documents");
    sessionStorage.setItem(
      "claimInfo",
      JSON.stringify(delayInfo)
    );
    sessionStorage.setItem("claim_type", "Delay Claim")
  };
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>
            Please provide these details for your
          </span>{" "}
          <span className="span-title">Travel Delay Claim...</span>  
        </div>
        <div className="cancellation-input">
          <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                What was the scheduled date & time of departure?
                </label>
                <br />
                <div className="postal-area-container">
                  <input
                    id="scheduledStartDate"
                    name="scheduledStartDate"
                    type="date"
                    onChange={handleInputChange}
                    value={delayInfo.scheduledStartDate}
                    placeholder="P.O.Box"
                  ></input>

                  <input
                    id="scheduledEndDate"
                    name="scheduledEndDate"
                    type="date"
                    onChange={handleInputChange}
                    value={delayInfo.scheduledEndDate}
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                What was the actual date & time of departure?
                </label>
                <br />
                <div className="postal-area-container">
                  <input
                    id="actualStartDate"
                    name="actualStartDate"
                    type="date"
                    onChange={handleInputChange}
                    value={delayInfo.actualStartDate}
                  ></input>

                  <input
                    id="actualEndDate"
                    name="actualEndDate"
                    type="date"
                    onChange={handleInputChange}
                    value={delayInfo.actualEndDate}
                  ></input>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="departureCountry"
                >
                  Number of hours of delay
                </label>
                <br />
                <input
                  id="delayHours"
                  name="delayHours"
                  type="number"
                  onChange={handleInputChange}
                  value={delayInfo.delayHours}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>

            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="flightNumber"
                >
                  Flight/ferry number
                </label>
                <br />
                <div className="postal-area-container">
                  <input
                    id="flightNumber"
                    name="flightNumber"
                    type="text"
                    onChange={handleInputChange}
                    value={delayInfo.flightNumber}
                    placeholder="Type here"
                  ></input>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container w-100">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="airline"
                >
                  Airline/ferry company
                </label>
                <br />
                <input
                  id="airline"
                  name="airline"
                  type="text"
                  onChange={handleInputChange}
                  value={delayInfo.airline}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "claim-options");
                setCurrentScreen("claim-options");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default DelayClaim;
