import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as GrIcons from "react-icons/gr";
import { useWizard } from "react-use-wizard";
import { toast } from "react-toastify";
import { makeGetRequest, makePostRequest } from "../../services/apiHandler";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function IndividualDetails({
  setCurrentScreen,
  individualStatus,
  groupStatus,
}: any) {
  const travel_option = sessionStorage.getItem("travel_option")!;
  const dispatch = useDispatch();
  const { previousStep, nextStep } = useWizard();
  const [travelInfo, setTravelInfo] = useState({
    startDate: "",
    endDate: "",
    userName: "",
    phoneNumber: "",
    location: "",
    yearOfBirth: "",
    reason: "",
    departureCountry: travel_option == "country" ? "Kenya" : "",
    destinationCountry: "",
  });

  const [reasons, setTravelReasons] = useState();
  const [countries, setCountries] = useState();
  const [isTravellingOptions, setIsTravellingOptions] =
    useState<boolean>(false);

  const isTravellingOptionsToMultipleCountries = () => {
    sessionStorage.setItem("isTravellingToMultipleCountries", "false");
    setIsTravellingOptions(false);
  };
  const isNotTravellingOptionsToMultipleCountries = () => {
    sessionStorage.setItem("isTravellingToMultipleCountries", "true");
    setIsTravellingOptions(true);
  };
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setTravelInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      reason: selectedOption.name,
    }));
  };

  const handleCountriesChange = (selectedOption: any) => {
    setTravelInfo((previousState: any) => ({
      ...previousState,
      destinationCountry: selectedOption.name,
    }));
  };

  const saveDetails = () => {
    if (!travelInfo.startDate) {
      toast.warning("Enter Travelling date!");
      document.getElementById("startDate")?.focus;
      return;
    }
    // let today = Date.now() - 86400;
    // let travellingDate = new Date(travelInfo.startDate).getTime()  ;
    // if (travellingDate < today) {
    //   toast.warning("Enter a valid travelling date!");
    //   return;
    // }
    if (!travelInfo.reason) {
      toast.warning("Please select a reason");
      document.getElementById("reason")?.focus;
      return;
    }
    if (!travelInfo.destinationCountry) {
      toast.warning("Enter Destination Country!");
      document.getElementById("destinationCountry")?.focus;
      return;
    }
    if (!travelInfo.departureCountry) {
      toast.warning("Enter Departure Country!");
      document.getElementById("departureCountry")?.focus;
      return;
    }

    if (!travelInfo.yearOfBirth) {
      toast.warning("Enter Date of Birth!");
      document.getElementById("yearOfBirth")?.focus;
      return;
    }
    if (!isTravellingOptions) {
      if (!travelInfo.endDate) {
        toast.warning("Enter a valid end date");
        document.getElementById("endDate")?.focus;
        return;
      }
      let today = new Date().getTime();
      let returnDate = new Date(travelInfo.endDate).getTime();
      let startDate = new Date(travelInfo.startDate).getTime();
      if (returnDate < today && returnDate > startDate) {
        toast.warning("Enter a valid return date!");
        return;
      }
    }
    let endDate = new Date(travelInfo.endDate);
    let startDate = new Date(travelInfo.startDate);
    if (endDate <= startDate) {
      toast.warning("Please select a valid end date");
      return;
    }

    let date = travelInfo.startDate.replaceAll("-", "/");
    let travelDate = date.split("/").reverse().join("/");
    sessionStorage.setItem("startDate", travelDate);
    sessionStorage.setItem("travelInfo", JSON.stringify(travelInfo));
    sessionStorage.setItem(
      "isTravellingToMultipleCountries",
      isTravellingOptions ? "true" : "false"
    );
    sessionStorage.setItem("travel_type", "Individual");
    if (dispatch(stepActions.onAddStep())) {
      nextStep();
    }
  };
  const getReasons = async () => {
    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/getTravelReasons`,
      {}
    );
    if (result.Status == 200) {
      setTravelReasons(result.Payload);
    } else {
      toast.warning("Unable to load travel reasons!");
    }
  };
  const getCountries = async () => {
    const result: IApiResponse = await makeGetRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/countries`
    );
    if (result.Status == 200) {
      setCountries(result.Payload);
    } else {
      toast.warning(result.Payload);
    }
  };
  useEffect(() => {
    getReasons();
    getCountries();
  }, []);
  return (
    <motion.div variants={variants} initial="enter" animate="center">
      <div className="ky-inputs-container-1">
        <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="coverType">
                What type of cover do you want?
              </label>
              <br />
              <div className="ky-input-wrapper-type">
                <div
                  className="individual"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentScreen("individual");
                  }}
                >
                  <input
                    id="coverType"
                    name="coverType"
                    type="radio"
                    checked={individualStatus}
                    onChange={handleInputChange}
                    value={"individual"}
                  />
                  <span className="ky-individual">Individual</span>
                </div>
                <div
                  className="group"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentScreen("group");
                  }}
                >
                  <input
                    id="coverType"
                    name="coverType"
                    type="radio"
                    checked={groupStatus}
                    onChange={handleInputChange}
                    value={travelInfo.startDate}
                  />
                  <span className="ky-group">Group</span>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="startDate">
                When are you travelling?{" "}
              </label>
              <br />
              <input
                id="startDate"
                name="startDate"
                type="date"
                onChange={handleInputChange}
                value={travelInfo.startDate}
              ></input>
            </motion.div>
          </div>
        </div>

        <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="reason">
                Please tell us your reason for travelling{" "}
              </label>
              <br />
              <Select
                options={reasons}
                onChange={handleChange}
                id={"reason"}
                className={"reason"}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#F6F8FB",
                    border: state.isFocused ? "none" : "#F6F8FB",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                  input: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "315px",
                    height: "47px",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#00000",
                    fontWeight: "bold",
                  }),
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                  }),
                }}
              />{" "}
            </motion.div>
          </div>

          {travel_option == "town" && (
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="destinationCountry"
                >
                  What is the final {travel_option} you are travelling to
                  (arrival)?
                </label>
                <br />
                <input
                  id="destinationCountry"
                  name="destinationCountry"
                  type="text"
                  onChange={handleInputChange}
                  value={travelInfo.destinationCountry}
                  placeholder="Type location"
                ></input>
              </motion.div>
            </div>
          )}
          {travel_option == "country" && (
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="reason">
                  What is the final country you are travelling to (arrival)?{" "}
                </label>
                <br />
                <Select
                  options={countries}
                  onChange={handleCountriesChange}
                  id={"destinationCountry"}
                  className={"destinationCountry"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#F6F8FB",
                      border: state.isFocused ? "none" : "#F6F8FB",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "315px",
                      height: "47px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#00000",
                      fontWeight: "bold",
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      border: "none",
                    }),
                  }}
                />{" "}
              </motion.div>
            </div>
          )}
        </div>
        <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="yearOfBirth">
                When is your birth date?{" "}
              </label>
              <br />
              <input
                id="yearOfBirth"
                name="yearOfBirth"
                type="date"
                onChange={handleInputChange}
                value={travelInfo.yearOfBirth}
              ></input>
            </motion.div>
          </div>
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className={
                travel_option == "country"
                  ? "ky-input-container mt-4 country-travel"
                  : "ky-input-container mt-4"
              }
            >
              <label
                className="text-left-label mb-2"
                htmlFor="departureCountry"
              >
                What {travel_option} are you travelling from (departure)?
              </label>
              <br />
              <input
                id="departureCountry"
                name="departureCountry"
                type="text"
                className={travel_option == "country" ? "country-travel" : ""}
                onChange={handleInputChange}
                value={travelInfo.departureCountry}
                placeholder="Type location"
              ></input>
            </motion.div>
          </div>
        </div>
        <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="phoneNumber">
                Are you travelling to one or multiple{" "}
                {travel_option == "country" ? "countries" : "towns"}?{" "}
              </label>
              <br />
              <div className="countries d-flex">
                <button
                  className={
                    !isTravellingOptions
                      ? "countries-selection multiple-country isOption-active"
                      : "countries-selection multiple-country"
                  }
                  onClick={() => isTravellingOptionsToMultipleCountries()}
                >
                  One
                </button>
                <button
                  className={
                    isTravellingOptions
                      ? "countries-selection one-country isOption-active"
                      : "countries-selection one-country"
                  }
                  onClick={() => isNotTravellingOptionsToMultipleCountries()}
                >
                  Multiple
                </button>
              </div>
            </motion.div>
          </div>
          {!isTravellingOptions && (
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="endDate">
                  When are you coming back?
                </label>
                <br />
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  onChange={handleInputChange}
                  value={travelInfo.endDate}
                ></input>
              </motion.div>
            </div>
          )}
        </div>

        <div className="mt-2 pointer-button">
          <div className="previous">
            <span
              onClick={() => {
                dispatch(stepActions.onRemoveStep());
                previousStep();
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <button className="next-btn" onClick={() => saveDetails()}>
            {" "}
            <span>
              Next{" "}
              <span className="GrIcon ms-3">
                <GrIcons.GrFormNextLink />
              </span>
            </span>{" "}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default IndividualDetails;
