import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as GrIcons from "react-icons/gr";
import InputFile from "../../../shared/file-uploads/InputFile";
import { useWizard } from "react-use-wizard";
import { stepActions } from "../../../store/Steps";
import { useDispatch } from "react-redux";


const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

export function InsuredDocs({ setCurrentScreen }: any) {
  const { nextStep, goToStep } = useWizard();

  const [userDocs, setuserDocs] = useState({
    idNumber: "",
    idPhotoBack: "",
    idPhotoFront: "",
    kraPin: "",
    kraPhoto: "",
    passportNumber: "",
    passportPhoto: "",
  });
  const dispatch = useDispatch()

  const screen = sessionStorage.getItem("travel_type")!
  const saveDetails = () => {
    if (userDocs.idNumber) {
      if (!userDocs.idPhotoFront) {
        toast.warning("Upload front ID!");
        return;
      }
      if (!userDocs.idPhotoBack) {
        toast.warning("Upload back ID!");
        return;
      }
    }
    
    if (userDocs.passportNumber) {
      if (!userDocs.passportPhoto) {
        toast.warning("Upload Passport Document!");
        return;
      }
      if (!userDocs.kraPin){
        toast.warning("Enter KRA Pin!");
        document.getElementById("kraPin")?.focus();
        return;
      }
    }
    if(!userDocs.idNumber && !userDocs.passportNumber){
      toast.warning("Please add ID or Passport datails!")
      return
    }
    if (!userDocs.kraPhoto) {
      toast.warning("Upload KRA Document!");
      return;
    }
    sessionStorage.setItem("userDocs", JSON.stringify(userDocs));
    screen != "Group" && sessionStorage.setItem("details-screen","Beneficiary-screen")
    screen == "Group" && sessionStorage.setItem("details-screen","Group-screen")
    sessionStorage.removeItem("docs-current-screen")
    
    if(dispatch(stepActions.onAddStep())){
      nextStep()
    }

  };
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setuserDocs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateDocs = (name: any, url: any) => {
    setuserDocs((prevState) => ({
      ...prevState,
      [name]: url,
    }));
  };

  useEffect(() => {
    setCurrentScreen("insurer-docs");
  });

  return (
    <div>
      <div id="insudocs">
        <div className="container">
          <motion.div
            variants={variants}
            initial="enter"
            animate="center"
            exit={{ opacity: 0 }}
            className="steps-conatiner2"
          >
            <div className="ky-inputs-container">
              <div className="reg-title text-left">
                Please submit the{" "}
                <span className="span-title">documents</span> below...
              </div>
              <br />
              <div className="ky-inputs-inner-container w-100">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="idNumber">
                      National ID{" "}
                    </label>
                    <br />
                    <input
                      id="idNumber"
                      name="idNumber"
                      type="text"
                      onChange={handleInputChange}
                      value={userDocs.idNumber}
                      placeholder="Type ID number"
                    ></input>
                  </motion.div>
                </div>
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="postal">
                      Upload ID Document
                    </label>
                    <br />
                    <div className="id-uploads-container">
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"id-front"}
                        allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon="https://drive.google.com/uc?id=1yk4t6ayTXde92M07PXW3E-9E6V5AxPKM"
                        updateDocs={updateDocs}
                        name="idPhotoFront"
                      />
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"id-back"}
                        allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon="https://drive.google.com/uc?id=17S4z8QfgMGH6RxGjgOq5eENqnzFIJTm1"
                        updateDocs={updateDocs}
                        name="idPhotoBack"
                      />
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className="ky-inputs-inner-container w-100">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label
                      className="text-left-label mb-2"
                      htmlFor="passportNumber"
                    >
                      Passport Number{" "}
                    </label>
                    <br />
                    <input
                      id="passportNumber"
                      name="passportNumber"
                      type="text"
                      onChange={handleInputChange}
                      value={userDocs.passportNumber}
                      placeholder="Type Passport number"
                    ></input>
                  </motion.div>
                </div>
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="postal">
                      Upload Passport Document
                    </label>
                    <br />
                    <div className="id-uploads-container">
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"passport-document"}
                        allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon="https://drive.google.com/uc?id=1ZQSpgFD9c4YwlsU69ZZAQdUfs1h1znTu"
                        updateDocs={updateDocs}
                        name="passportPhoto"
                      />
                    </div>
                  </motion.div>
                </div>
              </div>

              <div className="ky-inputs-inner-container w-100">
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="kraPin">
                      KRA Pin
                    </label>
                    <br />
                    <input
                      id="kraPin"
                      name="kraPin"
                      type="text"
                      onChange={handleInputChange}
                      value={userDocs.kraPin}
                      placeholder="Type KRA pin"
                    ></input>
                  </motion.div>
                </div>
                <div className="ky-input-wrapper">
                  <motion.div
                    animate={{ scale: [-0.7, 1] }}
                    transition={{ duration: 1 }}
                    className="ky-input-container mt-4"
                  >
                    <label className="text-left-label mb-2" htmlFor="postal">
                      Upload KRA Document
                    </label>
                    <br />
                    <div className="id-uploads-kraPhot">
                      <InputFile
                        fileLocation={"profile"}
                        aFileName={"kra-document"}
                        allowedFileExtentions={["png", "jpg", "jpeg", "pdf"]}
                        refIcon="https://drive.google.com/uc?id=1ZQSpgFD9c4YwlsU69ZZAQdUfs1h1znTu"
                        updateDocs={updateDocs}
                        name="kraPhoto"
                      />
                    </div>
                  </motion.div>
                </div>
              </div>

              {/* {loading && <Loader />} */}
              <div className="insurer-footer d-flex justify-content-between align-items-center mt-5">
                <div className="previous">
                  <span onClick={(e) => {
                    sessionStorage.setItem("docs-current-screen","insurer-details")
                    setCurrentScreen("insurer-details")
                    }}>
                    <span className="GrIcon me-2">
                      <GrIcons.GrFormPreviousLink />
                    </span>{" "}
                    <span className="previous">Previous</span>
                  </span>
                </div>
                <div className="" onClick={() => saveDetails()}>
                    <button className="next-btn">PROCEED</button>
                  </div>
              </div>
            </div>
            <br />
            <br />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
