import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import * as AiIcons from "react-icons/ai";
import * as IiCons from "react-icons/im";
import { useWizard } from "react-use-wizard";
import CurrencyFormater from "react-currency-format";
import { useDispatch } from "react-redux";
import { stepActions } from "../../../store/Steps";
const icon = require("../../../../assets/images/bar-icon.png");

function Quote({ quote, url, checkIfGeneratedQuoteIDIsFound, showCheckbox }: any) {
  const dispatch = useDispatch();
  const { nextStep, goToStep } = useWizard();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [quotesToShare, setQuotesToShare] = useState<any[]>([]);

  const SKEY = sessionStorage.getItem("SKEY");

  const buyPolicy = () => {
    sessionStorage.setItem("quote", JSON.stringify(quote));
    sessionStorage.setItem("quote_amount", quote.amount);
    if (SKEY) {
      if (dispatch(stepActions.updateStepTo(2))) {
        sessionStorage.setItem("isStep", "true");
        goToStep(4);
      }
    } else {
      if (dispatch(stepActions.onAddStep())) {
        sessionStorage.setItem("isStep", "true");
        nextStep();
      }
    }
  };

  const showDialog = () => {
    setShowPaymentDialog(true);
  };

  const hideDialog = () => {
    // let elem: any = document.getElementById("all-quotes-container") || "";
    // let elem: any = document.getElementById("all-quotes-container") || "";
    // elem.style.filter = "none";

    // elem.style.background = "none";
    setShowPaymentDialog(false);
  };

  const getMonthPayment = (): number => {
    let monthly = quote.amount * 0.09026032801;
    return monthly;
  };

  const getDeposits = (): number => {
    let deposit = quote.amount * 0.2;
    return deposit;
  };

  const payment_dialog = (
    <div className="l-dialog-container">
      <div className="confirmation-details">
        <div className="cancel d-flex justify-content-end" onClick={hideDialog}>
          <IiCons.ImCancelCircle className="cancel-icon" />
        </div>
        <h1 className="confirmation-icon">
          <img src={icon} alt="" />
        </h1>
        <h2 className="confirmation-amount">
          Monthly Payment:{" "}
          <CurrencyFormater
            value={getMonthPayment()}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </h2>
        <h4 className="confirmation-deposit">Deposit: <CurrencyFormater
            value={getDeposits()}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
          /></h4>
        <div className="confirmation-description">
          Get covered by paying through 3, 6, 9 or 12 monthly installments
        </div>
        <div className="terms mt-2" >
         <a className="terms-condition" target="_blank" href="https://mtekcdnstoragev2.blob.core.windows.net/admin/TrustGro_IPF_Terms_and_Conditions_29112021.pdf">Terms & Conditions</a> 
        </div>
        <div className="btn-proceed d-flex flex-column justify-content-center align-items-center mt-2">
          <button className="btn-confirm" onClick={() => buyPolicy()}>Cool</button>
        </div>
      </div>
    </div>
  );

  const handleSelectAllChange = (event:any, receivedQuote:any) => {
    // Get the existing quotesToShare from sessionStorage
    const existingQuotesToShare = JSON.parse(
      sessionStorage.getItem("sharedQuotes") || "[]"
    );

    let newQuote = receivedQuote;
    newQuote.isChecked = !newQuote.isChecked;
    // Check if the selected quote ID exists in sessionStorage
    const index = existingQuotesToShare.findIndex(
      (q:any) => q._id === newQuote._id
    );
   
    const checked = event.target.checked;
    if (checked) {
      // Generate a unique _id for the quote if it doesn't have one
      if (!newQuote._id) {
        newQuote._id = Math.floor(Math.random() * 1000 * 100);
      }
      let body: any = {};
      // Add the quote to the list of shared quotes if not already present
      if (index === -1) {
        existingQuotesToShare.push({ ...newQuote, ...body });
      }
    } else {
      // Remove the quote from the list of shared quotes if present
      if (index !== -1) {
        existingQuotesToShare.splice(index, 1);
      }
    }
    // Update the quotesToShare state with the updated IDs
    setQuotesToShare(existingQuotesToShare.map((q:any) => q._id));

    // Store the updated quotesToShare in sessionStorage
    sessionStorage.setItem(
      "sharedQuotes",
      JSON.stringify(existingQuotesToShare)
    );
  };

  useEffect(() => {
  }, []);

  return (
    <div>
      {showPaymentDialog && payment_dialog}
      <motion.div
        className="quote-container"
        animate={{ scale: [0.7, 1] }}
        transition={{ duration: 1 }}
        id="all-quotes-container"
      >
        <div className="basic-details">
          <div className="title-info">
            <h4>{quote.insurerName}</h4>
            <h5 className="pol-type">{quote.policy?.planName}</h5>
            <h6>
              Cover Limit:{" "}
              <CurrencyFormater
                value={quote.coverLimit}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </h6>
          </div>

          <div className="insurer-logo">
            <img src={url} alt="Logo"></img>
            {!checkIfGeneratedQuoteIDIsFound() && showCheckbox && (
            <div>
              <input
                checked={quote?.isChecked || false}
                onChange={(e) => handleSelectAllChange(e, quote)}
                type="checkbox"
                className="checkbox-data"
              />
            </div>
           )} 
          </div>
        </div>
        <br />
        <hr />
        <div className="payment-container">
          <div className="payment d-flex justify-content-between align-items-center">
            <div>
            <h3 className="payment-title-quote">
              Total Premium:{" "}
              <span className="payment-amount">
                <CurrencyFormater
                  value={quote.amount}
                  suffix={" Kshs"}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
            </h3>
            </div>
           
            <div className="payment-description">
              Get more information:{" "}
              <span className="payment-description-pdf">
               <a href={quote?.policyDocUrl[0]?.url} target="_blank" >Benefits.pdf <AiIcons.AiOutlineFilePdf /></a> 
              </span>
            </div>
          </div>
          <p>
            <small className="payment-tax-information">
              *Amount exclusive of tax*
            </small>
          </p>
        </div>
        <div className="price-container">
          <div>
          {quote.amount >= 10000 &&  <h5 className="total" onClick={showDialog}>View Monthly Payment Plan options?</h5> }
          </div>
          <div className="bg-green-button pointer" onClick={() => buyPolicy()}>
            Buy this policy
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Quote;
