import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import * as GrIcons from "react-icons/gr";

const powerByIcon = require("../../../assets/images/powered-by.png");
import mtekLogo  from "../../../assets/images/mtek-logo.svg"


function Confirmation() {
  const navigate = useNavigate();

  const quote_amount = Number(sessionStorage.getItem("quote_amount"));
  const quote_insurerName = sessionStorage.getItem("quote_insurerName");

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };

  const proceed = () => {
    navigate("/policies");
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="paym-confirmation-container px-2">
          <div className="paym-confirmation-wapper">
            <div className="paym-text-sm">
              Your payment request is being processed.
            </div>
            <div className="paym-text">
              You will receive a notification shortly via email and our team
              will commence issuance of your{" "}
              <span className="text-bold span-title">{quote_insurerName} </span>
              certificate.
            </div>
            <p className="red-paym-text mt-5">
              Didn’t receive M-PESA pop up? Pay using PAYBILL details below.
            </p>

            <div className="p-bills-conainer">
              <div className="p-bills-wrapper">
                <div className="p-alt-tit">Paybill</div>
                <div className="p-alt-box">
                  {sessionStorage.getItem("mpesaPaybillNo") || ""}
                </div>
              </div>
              <div className="p-bills-wrapper">
                <div className="p-alt-tit">Account Number</div>
                <div className="p-alt-box">
                  {sessionStorage.getItem("mtekPaymentRef") || ""}
                </div>
              </div>
              <div className="p-bills-wrapper">
                <div className="p-alt-tit">Amount</div>
                <div className="p-alt-box">
                  <CurrencyFormat
                    value={quote_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" Ksh"}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5" onClick={() => proceed()}>
              <button className="pointer-btn">VIEW MY POLICY</button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span onClick={(e) => navigate(-1)}>
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div className="powered-by">
              <a href="https://bit.ly/3FprVBJ" target="_blank" rel="noreferrer">
                <img src={powerByIcon} alt="powered by" />
              </a>
            </div>
          </div>
        </div>
        <br />
        <br />
      </motion.div>
    </div>
  );
}

export default Confirmation;
