import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

import mtekLogo  from "../../../assets/images/mtek-logo.svg"

function BaggageClaim({ setCurrentScreen }: any) {
  const navigate = useNavigate();

  const b = sessionStorage.getItem("baggageItems")!
  const bggItems = JSON.parse(b)
  const [itemInfo, setItemInfo] = useState({
    name: "",
    purchaseDate: "",
    shopName: "",
    townName: "",
    itemPrice: 0,
    claimPrice: 0,
  });

  const [baggageItems, setBaggageItems] = useState<any[]>(bggItems ? bggItems : []);
  useEffect(() => {
    setCurrentScreen("baggage-claim");
    sessionStorage.setItem("claim-screens", "baggage-claim");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setItemInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addItem = () => {
    if (!itemInfo.name) {
      toast.warning("Enter item name!");
      document.getElementById("name")?.focus;
      return;
    }
    if (!itemInfo.purchaseDate) {
      toast.warning("Enter purchase date!");
      document.getElementById("purchaseDate")?.focus;
      return;
    }
    if (!itemInfo.shopName) {
      toast.warning("Enter shop name");
      document.getElementById("shopName")?.focus;
      return;
    }
    if (!itemInfo.townName) {
      toast.warning("Enter town name!");
      document.getElementById("townName")?.focus;
      return;
    }
    if (!itemInfo.itemPrice) {
      toast.warning("Enter item price!");
      document.getElementById("itemPrice")?.focus;
      return;
    }
    if (!itemInfo.claimPrice) {
      toast.warning("Enter item claim price!");
      document.getElementById("claimPrice")?.focus;
      return;
    }
    if (Array.isArray(baggageItems)) {
      setBaggageItems([...baggageItems, itemInfo as any]);
      setItemInfo({
        name: "",
        purchaseDate: "",
        shopName: "",
        townName: "",
        itemPrice: 0,
        claimPrice: 0,
      });
    } else {
      setBaggageItems([itemInfo as any]);
      setItemInfo({
        name: "",
        purchaseDate: "",
        shopName: "",
        townName: "",
        itemPrice: 0,
        claimPrice: 0,
      });
    }
  };

  const saveDetails = () => {
    if(baggageItems.length < 1){
      toast.warning("Please add atleast one baggage item!")
      return
    }
      sessionStorage.setItem('baggageItems', JSON.stringify(baggageItems))
      setCurrentScreen("baggage-claim-additional")
      sessionStorage.setItem('claim-screens', "baggage-claim-additional")
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>
            Please provide these details for your
          </span>{" "}
        <span className="span-title">Baggage Claim...</span>  
        </div>
        <div className="cancellation-input">
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="name">
                  Full details of item lost/damaged
                </label>
                <br />
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  value={itemInfo.name}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="purchaseDate">
                  When was the item purchased?{" "}
                </label>
                <br />
                <input
                  id="purchaseDate"
                  name="purchaseDate"
                  type="date"
                  onChange={handleInputChange}
                  value={itemInfo.purchaseDate}
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="shopName">
                  Where was it purchased from?
                </label>
                <br />
                <input
                  id="shopName"
                  name="shopName"
                  type="text"
                  onChange={handleInputChange}
                  value={itemInfo.shopName}
                  placeholder="Type shop name"
                ></input>
                <div className="town-name mt-2">
                  <input
                    id="townName"
                    name="townName"
                    type="text"
                    onChange={handleInputChange}
                    value={itemInfo.townName}
                    placeholder="Type town name"
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="itemPrice">
                  How much was the price?{" "}
                </label>
                <br />
                <input
                  id="itemPrice"
                  name="itemPrice"
                  type="number"
                  onChange={handleInputChange}
                  value={itemInfo.itemPrice}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="claimPrice">
                  How much was claimed?
                </label>
                <br />
                <input
                  id="claimPrice"
                  name="claimPrice"
                  type="number"
                  onChange={handleInputChange}
                  value={itemInfo.claimPrice}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <hr />
        <div className="claim-options">
          {baggageItems.length > 0 &&
            baggageItems.map((item, index) => {
              return (
                <div className="baggage-item" key={index}>
                  <div className="item-name">{item.name}</div>
                  <div className="item-price">
                    Price:{" "}
                    <CurrencyFormat
                      value={item.itemPrice}
                      displayType={"text"}
                      prefix={"Ksh. "}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </div>
                  <div className="item-price">
                    Amount Claimed:{" "}
                    <CurrencyFormat
                      value={item.claimPrice}
                      displayType={"text"}
                      prefix={"Ksh. "}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="add-claim-item">
          <button className="btn-add-item" onClick={() => addItem()}>
            Add Another Item
          </button>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "claim-options");
                setCurrentScreen("claim-options");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default BaggageClaim;
