import { motion } from "framer-motion";
import React, { useState } from "react";
import * as GrIcons from "react-icons/gr";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { makePostRequest } from "../../../services/apiHandler";
import Loader from "../../../shared/loader/Loader";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const visaImage = require("../../../../assets/images/visa.png");
const powerByIcon = require("../../../../assets/images/powered-by.png");
const mtekLogo = require("../../../../assets/images/mtek-logo.svg");

function Visa() {
  const navigate = useNavigate();

  const [paymInfo, setPaymInfo] = useState({
    cardHolderName: "",
    cardpan: "",
    cvv: "",
    expiryDate: "",
  });
  const [loading, setLoading] = useState(false);
  const quote_amount = Number(sessionStorage.getItem("quote_amount"));
  const quote_id = sessionStorage.getItem("quote_id");
  const SKEY = sessionStorage.getItem("SKEY") || "";
  const paymentOption = sessionStorage.getItem("paymentOption");
  const planTenure = Number(sessionStorage.getItem("planTenure"));
  const uid = sessionStorage.getItem("uid");
  const proceed = () => {
    if (!paymInfo.cardHolderName) {
      toast.warning("Enter Card Holder Name!");
      document.getElementById("cardHolderName")?.focus();
      return;
    }
    if (!paymInfo.cardpan) {
      toast.warning("Enter Card number!");
      document.getElementById("cardpan")?.focus();
      return;
    }
    if (!paymInfo.expiryDate) {
      toast.warning("Enter Card expiry date!");
      document.getElementById("expiryDate")?.focus();
      return;
    }
    if (!paymInfo.cvv) {
      toast.warning("Enter Card CVV!");
      document.getElementById("cvv")?.focus();
      return;
    }
    let exDate = paymInfo.expiryDate.replace("/", "");
    paymInfo.expiryDate = exDate;
    processPayment();
  };
  const processPayment = async () => {
    setLoading(true);
    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_IPF_URL}/stagePayment`,
      getStagePaymentPayload()
    );

    if (result.Status === 200) {
      let stageResponse = result.Payload;
      sessionStorage.setItem(
        "stagePaymentResponse",
        JSON.stringify(result.Payload)
      );

      const res: IApiResponse = await makePostRequest(
        `${process.env.REACT_APP_MPESA_URL}/nextpayment`,
        {
          quoteid: quote_id,
        },
        {
          SKEY: SKEY,
          ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
          apiKey: `${process.env.REACT_APP_MODULE_ID}`,
        }
      );

      setLoading(false);

      if (res.Status === 200) {
        let nextPaymentResponse = res.Payload;
        if (paymentOption == "renewal" || paymentOption == "pp") {
          const p: any = sessionStorage.getItem("caculatedPlan") || {};
          const plan = JSON.parse(p);
          sessionStorage.setItem("ppPayable", plan.downPaymentPayable);
        }
        sessionStorage.setItem(
          "nextPaymentResponse",
          JSON.stringify(res.Payload)
        );
        initiatePayment({
          amount: nextPaymentResponse?.amount,
          schedule: stageResponse?.schedule,
          paymentid: nextPaymentResponse._id,
        });
      } else if (res.Status === 541) {
        toast.warning("Session expired please login again!");
        navigate("/login");
      } else {
        toast.error("Something went wrong please try again!");
        setLoading(false);
      }
    } else {
      toast.error("Something went wrong please try again!");
      setLoading(false);
    }
  };

  const initiatePayment = async ({ amount, schedule, paymentid }: any) => {
    let body = {
      _id: quote_id,
      amount: amount,
      broker: "mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007",
      cardHolderName: paymInfo.cardHolderName,
      cardpan: paymInfo.cardpan,
      currency: "KES",
      cvv: paymInfo.cvv,
      expiryDate: paymInfo.expiryDate,
      feduid: uid,
      id: "travel",
      internalTenant: 1,
      mobileNumber: "",
      paymentid: paymentid,
      redeemBimaPoints: 0.0,
      ref: "travel",
      referralCode: "N/A",
      tenantUUID: "mtek-dbbcd171-aa7c-4405-ad9c-4f8ab9ee0007",
      transactionDescription: "travel",
      transactionRef: quote_id,
      transactionType: "CARD",
    };

    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_MPESA_URL}/initiatepayment`,
      body,
      {
        SKEY: SKEY,
        moduleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (result.Status === 200) {
      setLoading(false);
      let data = result.Payload;
      sessionStorage.setItem("mpesaPaybillNo", data.mpesaPaybillNo);
      sessionStorage.setItem("mtekPaymentRef", data.mtekPaymentRef);
      sessionStorage.setItem("paymResponse", JSON.stringify(data));
      navigate("/payments/make/confirm");
    } else if (result.Status === 541) {
      toast.warning("Session expired please login again");
      navigate("/login");
    } else {
      setLoading(false);
      toast.error(
        "Payment could not be processed, our sales team will contact you shortly!"
      );
    }
  };

  const getStagePaymentPayload = (): any => {
    let body: any = {
      bank: "N/A",
      cover: "travel",
      customPerUnitRepaymentAmount: 0,
      isDownPayment: false,
      kyosk: false,
      _id: quote_id,
    };
    if (paymentOption == "pp") {
      const p: any = sessionStorage.getItem("caculatedPlan") || {};

      const plan = JSON.parse(p);
      body.bank = "TRUSTGRO";
      body["paymentPlanDetails"] = plan;
      body["plan"] = planTenure;
      return body;
    } else if (paymentOption == "renewal") {
      const p: any = sessionStorage.getItem("caculatedPlan") || {};
      const plan = JSON.parse(p);
      body.bank = "TRUSTGRO";
      body["paymentPlanDetails"] = plan;
      body["plan"] = planTenure;
      return body;
    } else {
      return body;
    }
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPaymInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={mtekLogo} alt="travelduqa icon" />
        </div>
        <div className="ky-inputs-container">
          <div className="reg-title methods-title text-left">
            <span>We’re almost there...</span> <br />
            Please pay{" "}
            <span className="span-title">
              {" "}
              <CurrencyFormat
                value={quote_amount}
                displayType={"text"}
                prefix={"Ksh. "}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />{" "}
            </span>
            <span>via Card option</span>{" "}
          </div>
          <div className="mt-5 mb-5">
            <img src={visaImage} alt="Mpesa" />
          </div>
          <label className="text-left-label mb-2" htmlFor="card">
            Enter your card details below.
          </label>
          <div className="card-fields ky-inputs-inner-container ky-inputs-inner-container-visa w-100">
            <div className="ky-input-wrapper ky-input-wrapper-visa">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="py-input-container mt-2"
              >
                <div className="input-holder">
                  <label
                    className="text-left-label visa-text-label"
                    htmlFor="cardHolderName"
                  >
                    Card name
                  </label>
                  <input
                    id="cardHolderName"
                    name="cardHolderName"
                    type="text"
                    className="input"
                    onChange={handleInputChange}
                    value={paymInfo.cardHolderName}
                    placeholder="Card Name"
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper ky-input-wrapper-visa">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="py-input-container mt-2"
              >
                <div className="input-holder">
                  <label
                    className="text-left-label tvisa-text-label"
                    htmlFor="cardpan"
                  >
                    Card Number
                  </label>
                  <input
                    id="cardpan"
                    name="cardpan"
                    type="text"
                    className="input"
                    onChange={handleInputChange}
                    value={paymInfo.cardpan}
                    placeholder="4698   2347   2445 3447"
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper ky-input-wrapper-visa">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="py-input-container mt-2"
              >
                <div className="input-holder">
                  <label
                    className="text-left-label visa-text-label"
                    htmlFor="expiryDate"
                  >
                    Exp Date
                  </label>
                  <input
                    id="expiryDate"
                    name="expiryDate"
                    type="text"
                    className="input"
                    onChange={handleInputChange}
                    value={paymInfo.expiryDate}
                    placeholder="mm/yy"
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper ky-input-wrapper-visa">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="py-input-container mt-2"
              >
                <div className="input-holder">
                  <label className="text-left-label visa-text-label" htmlFor="cvv">
                    CVV
                  </label>
                  <input
                    id="cvv"
                    name="cvv"
                    type="text"
                    className="input"
                    onChange={handleInputChange}
                    value={paymInfo.cvv}
                  ></input>
                </div>
              </motion.div>
            </div>
          </div>
          {loading && <Loader />}
          {!loading && (
            <div className="mt-5" onClick={() => proceed()}>
              <button className="pointer-btn">Confirm Payment</button>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div className="previous">
              <span onClick={(e) => navigate(-1)}>
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div className="powered-by">
              <a href="https://bit.ly/3FprVBJ" target="_blank" rel="noreferrer">
                <img src={powerByIcon} alt="powered by" />
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Visa;
